import React, {useEffect, useState} from "react";
import ConfirmWindow from "./ConfirmWindow";
import CustomSelectType1 from "./CustomSelectType1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import SelectTable from "./SelectTable";
import FetchRequest from "../fetchRequest";
import {LogarithmicScale} from "chart.js";

const NotificationModalCreate = ({action, setModalCreate, editNotification, applyChange}) => {
    const [selectedUsers, setSelectedUsers] = useState({
        Users: [],
        IsSelectAllUsers: false
    })
    const [selectedTasksProjects, setSelectedTasksProjects] = useState({
        Tasks: [],
        Projects: [],
        IsSelectAllTasks: false,
        IsSelectAllProjects: false,
    })
    const [isCyclic, setIsCyclic] = useState(false)
    const [units, setUnits] = useState([])
    const [isOpenUnits, setIsOpenUnits] = useState(false)
    const [selectUnit, setSelectUnit] = useState(null)
    const [isOpenSelectTableUsers, setIsOpenSelectTableUsers] = useState(false)
    const [isOpenSelectTableTasksProjects, setIsOpenSelectTableTasksProject] = useState(false)
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)

    const handlerModalCreateClose = (e) => {
        if (e.target.className === "modal-window") {
            setModalCreate(false)
        }
    }

    const handlerDeleteSelectedUser = (user) => {
        setSelectedUsers(prevState => ({
            Users: prevState.Users.filter(record => record.ID !== user.ID),
            IsSelectAllUsers: false
        }))
    }

    const handlerDeleteSelectTaskProject = (record, key) => {
        if (key === "Task") {
            setSelectedTasksProjects(prevState => ({
                ...prevState,
                Tasks: prevState.Tasks.filter(rec => rec.ID !== record.ID)
            }))
        } else {
            setSelectedTasksProjects(prevState => ({
                ...prevState,
                Projects: prevState.Projects.filter(rec => rec.ID !== record.ID)
            }))
        }
    }

    useEffect(() => {
        FetchRequest("GET", "/get_time_units", null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setUnits(response.data)
                        if (action === "create") {
                            setSelectUnit(response.data[0])
                        }
                    } else {
                        setUnits([])
                    }

                    if (action === "edit") {
                        setIsCyclic(editNotification.IsCyclic)
                    }
                }
            })
    }, [])

    const validateData = () => {
        let text, tasks, projects, dateSend, unitValue, deadline
        let users = []
        let result = true

        if (selectedUsers.Users.length > 0) {
            for (let user of selectedUsers.Users) {
                users.push({
                    User: user
                })
            }
            // users = selectedUsers.Users
            document.querySelector("#selected_users").style.border = "1px solid #e5e5e5"
        } else {
            result = false
            document.querySelector("#selected_users").style.border = "1px solid #ff0000"
        }

        if (document.querySelector("#text").value.length > 0) {
            text = document.querySelector("#text").value
            document.querySelector("#text").style.border = "1px solid #e5e5e5"
        } else {
            result = false
            document.querySelector("#text").style.border = "1px solid #ff0000"
        }

        tasks = selectedTasksProjects.Tasks
        projects = selectedTasksProjects.Projects


        if (action === "create") {
            if (document.querySelector("#date_send").value) {
                let currentDate = new Date
                dateSend = new Date(document.querySelector("#date_send").value).getTime()
                if (currentDate.getTime() > dateSend) {
                    result = false
                    document.querySelector("#date_send").style.border = "1px solid #ff0000"
                } else {
                    dateSend = Number(dateSend)
                    document.querySelector("#date_send").style.border = "1px solid #e5e5e5"
                }
            } else {
                result = false
                document.querySelector("#date_send").style.border = "1px solid #ff0000"
            }
        } else {
            dateSend = new Date(document.querySelector("#date_send").value).getTime()

            if (isCyclic) {
                if (dateSend < new Date(editNotification.DateSend)) {
                    result = false
                    document.querySelector("#date_send").style.border = "1px solid #ff0000"
                } else {
                    dateSend = Number(dateSend)
                    document.querySelector("#date_send").style.border = "1px solid #e5e5e5"
                }
            } else {
                let currentDate = new Date
                if (currentDate.getTime() > dateSend) {
                    result = false
                    document.querySelector("#date_send").style.border = "1px solid #ff0000"
                } else {
                    dateSend = Number(dateSend)
                    document.querySelector("#date_send").style.border = "1px solid #e5e5e5"
                }
            }
        }

        if (isCyclic) {
            if (document.querySelector("#unit_value").value > 0) {
                unitValue = {
                    Int64: Number(document.querySelector("#unit_value").value),
                    Valid: true
                }
                document.querySelector("#unit_value").style.border = "1px solid #e5e5e5"
            } else {
                unitValue = {
                    Int64: 0,
                    Valid: false
                }
                result = false
                document.querySelector("#unit_value").style.border = "1px solid #ff0000"
            }

            if (document.querySelector("#deadline").value) {
                let currentDate = new Date
                deadline = {
                    Int64: Number(new Date(document.querySelector("#deadline").value).getTime()),
                    Valid: false
                }
                if (currentDate.getTime() > deadline.Int64 || dateSend > deadline.Int64) {
                    result = false
                    document.querySelector("#deadline").style.border = "1px solid #ff0000"
                } else {
                    document.querySelector("#deadline").style.border = "1px solid #e5e5e5"
                    deadline.Valid = true
                }
            }
        }

        if (result) {
            return {
                ConnectedUser: users,
                Text: text,
                Tasks: tasks,
                Projects: projects,
                DateSend: dateSend,
                IsCyclic: isCyclic,
                TimeUnit: selectUnit,
                TimeUnitValue: unitValue,
                Deadline: deadline
            }
        } else {
            return false
        }
    }

    const handlerCreateNotification = () => {
        let result = validateData()
        setIsOpenConfirmWindow(false)

        if (result) {
            let uri

            if (action === "create") {
                uri = "/create_notification"
            } else if (action === "edit") {
                uri = "/change_notification"

                let changeUsers = false
                let changeTasks = false
                let changeProjects = false

                if (editNotification.Tasks == null) {
                    editNotification.Tasks = []
                }

                if (editNotification.Projects == null) {
                    editNotification.Projects = []
                }

                if (editNotification.ConnectedUser.length === selectedUsers.Users.length) {
                    for (let newUser of selectedUsers.Users) {
                        let newUserExist = false
                        
                        for (let oldUser of editNotification.ConnectedUser) {
                            if (newUser.ID === oldUser.User.ID) {
                                newUserExist = true
                            }
                        }
                        
                        if (!newUserExist) {
                            changeUsers = true
                            break
                        }
                    }
                } else {
                    changeUsers = true
                }

                if (editNotification.Tasks.length === selectedTasksProjects.Tasks.length) {
                    for (let newTask of selectedTasksProjects.Tasks) {
                        let newTaskExist = false

                        for (let oldTask of editNotification.Tasks) {
                            if (newTask.ID === oldTask.ID) {
                                newTaskExist = true
                            }
                        }

                        if (!newTaskExist) {
                            changeTasks = true
                            break
                        }
                    }
                } else {
                    changeTasks = true
                }

                if (editNotification.Projects.length === selectedTasksProjects.Projects.length) {
                    for (let newProject of selectedTasksProjects.Projects) {
                        let newProjectExist = false

                        for (let oldProject of editNotification.Projects) {
                            if (newProject.ID === oldProject.ID) {
                                newProjectExist = true
                            }
                        }

                        if (!newProjectExist) {
                            changeProjects = true
                            break
                        }
                    }
                } else {
                    changeProjects = true
                }

                result = {
                    ID: editNotification.ID,
                    Status: editNotification.Status,
                    LastSend: editNotification.LastSend,
                    Creator: editNotification.Creator,
                    IsChangeUsers: changeUsers,
                    IsChangeTasks: changeTasks,
                    IsChangeProjects: changeProjects,
                    ...result
                }
            }

            FetchRequest("POST", uri, result)
                .then(response => {
                    if (response.success) {
                        setModalCreate(false)
                        if (action === "edit") {
                            applyChange(response.data)
                        }
                    }
                })
        }
    }

    const handlerOpenConfirmWindow = () => {
        if (validateData()) {
            setIsOpenConfirmWindow(true)
        }
    }

    useEffect(() => {
        if (editNotification != null) {
            let usersArray = []
            let tasksArray = []
            let projectsArray = []

            for (let item of editNotification.ConnectedUser) {
                usersArray.push(item.User)
            }

            setSelectedUsers({
                Users: usersArray,
                IsSelectAllUsers: false
            })

            if (editNotification.Tasks != null) {
                tasksArray = editNotification.Tasks
            }
            if (editNotification.Projects != null) {
                projectsArray = editNotification.Projects
            }

            setSelectedTasksProjects({
                Tasks: tasksArray,
                Projects: projectsArray,
                IsSelectAllTasks: false,
                IsSelectAllProjects: false,
            })

            setSelectUnit(editNotification.TimeUnit)

            setIsCyclic(editNotification.IsCyclic)
        }
    }, [editNotification])

    return (
        <div className={"modal-window"} onMouseDown={handlerModalCreateClose}>
            {isOpenConfirmWindow && <ConfirmWindow text={action === "create" ? "Создать уведомление?"
                : "Изменить уведомление?"}
                                                   setWindowState={setIsOpenConfirmWindow}
                                                   confirmFunction={handlerCreateNotification}/>}
            {isOpenSelectTableUsers && <SelectTable uri={"/get_all_active_users"} type={"select-users"} setModalOpen={setIsOpenSelectTableUsers} selectedRecords={selectedUsers} setSelectedRecords={setSelectedUsers} />}
            {isOpenSelectTableTasksProjects && <SelectTable uri={"/get_all_tasks_and_projects"} type={"select-tasks-projects"} setModalOpen={setIsOpenSelectTableTasksProject} selectedRecords={selectedTasksProjects} setSelectedRecords={setSelectedTasksProjects} />}
            <div className="form">
                <h2>{action === "create" ? "Создание уведомления" : "Изменение уведомления"}</h2>
                <div className="fields">
                    <label>
                        <span>Получатели:</span>
                        <div className="selected-contain" id="selected_users">
                            {selectedUsers.IsSelectAllUsers ? <span className="bg-gradient" onClick={() => setSelectedUsers({Users: [], IsSelectAllUsers: false})}>Все пользователи <FontAwesomeIcon icon={faXmark} /></span> :
                                selectedUsers.Users.map((user, index) => (
                                    <span key={"selected-record-"+index} className={"bg-"+user.Role.Color} onClick={() => handlerDeleteSelectedUser(user)}>{user.Name.split(" ").length === 3 ?
                                        `${user.Name.split(" ")[0]} ${user.Name.split(" ")[1][0]}. ${user.Name.split(" ")[2][0]}.` : user.Name} <FontAwesomeIcon icon={faXmark} /></span>
                                ))}
                        </div>
                        <div className="button" onClick={() => setIsOpenSelectTableUsers(true)}>Добавить</div>
                    </label>
                    <label>
                        <span>Текст:</span>
                        <textarea id="text" cols="30" rows="4" placeholder="Текст..." defaultValue={action === "edit" ? editNotification.Text : ""}></textarea>
                    </label>
                    <label>
                        <span>Привязанные задачи/проекты:</span>
                        <div className="selected-contain" id="selected_tasks_projects">
                                {selectedTasksProjects.IsSelectAllTasks ? <span className={"bg-gradient"} onClick={() => setSelectedTasksProjects(prevState => ({...prevState, Tasks: [], IsSelectAllTasks: false}))}>Все задачи <FontAwesomeIcon icon={faXmark} /></span>
                                    : selectedTasksProjects.Tasks.length > 0 && selectedTasksProjects.Tasks.map((task,  index) => (
                                    <span key={"selected-record-task-"+index} className={"bg-blue"} onClick={() => handlerDeleteSelectTaskProject(task, "Task")}>{task.Name} <FontAwesomeIcon icon={faXmark} /></span>
                                ))}
                                {selectedTasksProjects.IsSelectAllProjects ? <span className={"bg-gradient"} onClick={() => setSelectedTasksProjects(prevState => ({...prevState, Projects: [], IsSelectAllProjects: false}))}>Все проекты <FontAwesomeIcon icon={faXmark} /></span>
                                    : selectedTasksProjects.Projects.length > 0 && selectedTasksProjects.Projects.map((project,  index) => (
                                    <span key={"selected-record-project-"+index} className={"bg-green"} onClick={() => handlerDeleteSelectTaskProject(project, "Project")}>{project.Name} <FontAwesomeIcon icon={faXmark} /></span>
                                ))}

                        </div>
                        <div className="button" onClick={() => setIsOpenSelectTableTasksProject(true)}>Добавить</div>
                    </label>
                    <label>
                        <span>Дата отправки:</span>
                        <input type="datetime-local" id="date_send" defaultValue={action === "edit" ? new Date(editNotification.DateSend - new Date().getTimezoneOffset()*60000).toISOString().slice(0,16) : ""}/>
                    </label>
                    <label className="checkbox">
                        <input type="checkbox" checked={isCyclic} onChange={() => setIsCyclic(!isCyclic)}/>
                        <span>Цикличное уведомление</span>
                    </label>
                    {isCyclic &&
                        <div className="hide-block">
                            <label>
                                <span>Частота повторения:</span>
                                <div className="row-inputs">
                                    <input type="number" id="unit_value" defaultValue={action === "edit" ? editNotification.TimeUnitValue.Int64 : ""}/>
                                    <CustomSelectType1 defaultValue={selectUnit.TranslateValue}
                                                       values={units}
                                                       name={"unit"}
                                                       isOpen={isOpenUnits}
                                                       setOpen={setIsOpenUnits}
                                                       setSelectedValue={setSelectUnit}
                                                       type={"default"}
                                    />
                                </div>
                            </label>
                            <label>
                                <span>Крайний срок уведомления:</span>
                                <input type="datetime-local" id="deadline" defaultValue={action === "edit" ? new Date(editNotification.Deadline.Int64 - new Date().getTimezoneOffset()*60000).toISOString().slice(0,16) : ""}/>
                            </label>
                        </div>
                    }
                    <div className="buttons">
                        {action === "create" ? <button className={"create"} onClick={handlerOpenConfirmWindow}>Создать</button>
                            : <button className={"create"} onClick={handlerOpenConfirmWindow}>Сохранить</button>}
                        <button className={"cancel"} onClick={() => setModalCreate(false)}>Отмена</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotificationModalCreate