import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDiagramProject,
    faListCheck,
    faUsers, faRightFromBracket, faBook, faUser
} from "@fortawesome/free-solid-svg-icons";
import API_DOMAIN from "../config";
import FetchRequests from "../fetchRequest";

const Aside = () => {
    const [active, setActive] = useState("")
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)
    const [profile, setProfile] = useState({
        ID: 0,
        Login: "",
        Name: "",
    })

    useEffect(() => {
        FetchRequests("GET", "/get_profile", null)
            .then((response) => {
                if (response.success && response.data != null) {
                    setProfile(response.data)
                }
            })
    }, [])

    const switchIsOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const hrefElements = window.location.href.split("/");
        let currentLocation = hrefElements[3]
        if (currentLocation === "") {
            currentLocation = "index"
        }

        switch (location.pathname) {
            case "/": document.title = "ERP - Главная"; break
            case "/login": document.title = "ERP - Авторизация"; break
            case "/tasks": document.title = "ERP - Задачи"; break
            case "/projects": document.title = "ERP - Проекты"; break
            case "/events": document.title = "ERP - События"; break
            case "/users": document.title = "ERP - Пользователи"; break
            case "/profile": document.title = "ERP - Профиль"; break
        }

        setActive(currentLocation)
    }, [location.pathname])

    const handlerExit = () => {
        let options = {
            method: "GET",
            headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
        }

        fetch(`${API_DOMAIN.HTTP}/exit`, options)
            .then(response => {
                if (response.status === 200) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user-role")
                    window.location.href = "/login"
                }
            })
            .catch(error => console.error(error))
    }

    return (
        <nav className={isOpen ? "active" : ""}>
            <div className="menuBtn" onClick={switchIsOpen}></div>
            <ul>
                {/*<Link to={"/"}>*/}
                {/*    <li className={active === "index" ? "active" : ""}>*/}
                {/*            <span className="icon"><FontAwesomeIcon icon={faHouse} /></span>*/}
                {/*            <span className="text">Главная</span>*/}
                {/*    </li>*/}
                {/*</Link>*/}
                <Link to={"/tasks"}>
                    <li className={active === "tasks" ? "active" : ""}>
                        <span className="icon"><FontAwesomeIcon icon={faListCheck} /></span>
                        <span className="text">Задачи</span>
                    </li>
                </Link>
                <Link to={"/projects"}>
                    <li className={active === "projects" ? "active" : ""}>
                        <span className="icon"><FontAwesomeIcon icon={faDiagramProject} /></span>
                        <span className="text">Проекты</span>
                    </li>
                </Link>
                <Link to={"/events"}>
                    <li className={active === "events" ? "active" : ""}>
                        <span className="icon"><FontAwesomeIcon icon={faBook} /></span>
                        <span className="text">События</span>
                    </li>
                </Link>
                <Link to={"/users"}>
                    <li id="users" className={active === "users" ? "active" : ""}>
                        <span className="icon"><FontAwesomeIcon icon={faUsers} /></span>
                        <span className="text">Пользователи</span>
                    </li>
                </Link>
                {/*<li className="settings">*/}
                {/*    /!*<div className="LD-mode">*!/*/}
                {/*    /!*    <span className="icon"><div className="switch"><div></div></div></span>*!/*/}
                {/*    /!*    <span className="text">Тёмная тема</span>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    <Link to={"/exit"}>*/}
                {/*        <li className={active === "projects" ? "active" : ""}>*/}
                {/*            <span className="icon"><FontAwesomeIcon icon={faRightFromBracket} /></span>*/}
                {/*            <span className="text">Выход</span>*/}
                {/*        </li>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <Link to={"/profile"} className={"profile"}>
                    <li className={active === "profile" ? "active" : ""}>
                        <span className="icon"><FontAwesomeIcon icon={faUser} /></span>
                        <span className="text">{profile.Login}</span>
                    </li>
                </Link>
                <p className={"settings"} onClick={handlerExit}>
                    <li>
                        <span className="icon"><FontAwesomeIcon icon={faRightFromBracket} /></span>
                        <span className="text">Выход</span>
                    </li>
                </p>
            </ul>
        </nav>
    )
}

export default Aside