import React, {useEffect, useState} from "react";
import FetchRequest from "../fetchRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faPen, faUsers} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Notification from "./Notification";
import {Pie} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import Preload from "./Preload";
import API_DOMAIN from "../config";
import ConfirmWindow from "./ConfirmWindow";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProfilePage = () => {
    const [user, setUser] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [viewMode, setViewMode] = useState("preview")
    const [sendBody, setSendBody] = useState(null)
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)
    const [sliderButtonLeft, setSliderButtonLeft] = useState(false)
    const [sliderButtonRight, setSliderButtonRight] = useState(true)
    const [tasksStatistics, setTasksStatistics] = useState([])
    const [projectStatistics, setProjectStatistics] = useState([])

    useEffect(() => {
        FetchRequest("GET", "/get_full_profile", null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setIsLoaded(true)
                        setUser(response.data.User)
                        setTasksStatistics(response.data.TasksStatistics)
                        setProjectStatistics(response.data.ProjectsStatistics)
                    }
                }
            })
    }, [])

    const checkUniqueConcretelyData = async (checkField, fieldValue) => {
        try {
            const options = {
                method: "POST",
                headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
                body: JSON.stringify(fieldValue)
            };

            const response = await fetch(`${API_DOMAIN.HTTP}/check_unique_concretely_data/${checkField}`, options);

            if (response.status === 401) {
                localStorage.removeItem("token");
                window.location.href = "/login";
            }

            if (response.status === 403) {
                window.location.href = "/";
            }

            const data = await response.json();

            if (data != null) {
                return data;
            } else {
                return { Error: true };
            }
        } catch (error) {
            console.error(error);
            return { Error: true };
        }
    }

    const validateData = async () => {
        let name,login,password,phone,email, telegram
        let result = true

        if (document.querySelector("#name").value.trim().length > 0) {
            name = document.querySelector("#name").value
            document.querySelector("#name").style.borderBottom = "1px solid #e5e5e5"
        } else {
            document.querySelector("#name").style.borderBottom = "1px solid #ff0000"
            result = false
        }

        if (document.querySelector("#login").value.trim().length > 0) {
            login = document.querySelector("#login").value
            document.querySelector("#login").style.borderBottom = "1px solid #e5e5e5"
        } else {
            document.querySelector("#login").style.borderBottom = "1px solid #ff0000"
            result = false
        }

        if (document.querySelector("#password").value.trim().length > 0) {
            if (document.querySelector("#password").value.trim() === document.querySelector("#password_confirm").value.trim()) {
                password = document.querySelector("#password").value
                document.querySelector("#password").style.borderBottom = "1px solid #e5e5e5"
            } else {
                document.querySelector("#password").style.borderBottom = "1px solid #ff0000"
                result = false
            }
        } else {
            password = ""
        }

        if (document.querySelector("#phone").value.trim().length > 0) {
            phone = {
                String: document.querySelector("#phone").value,
                Valid: true,
            }
        } else {
            phone = {
                String: "",
                Valid: false,
            }
        }

        if (document.querySelector("#email").value.trim().length > 0) {
            email = {
                String: document.querySelector("#email").value,
                Valid: true,
            }
        } else {
            email = {
                String: "",
                Valid: false,
            }
        }

        if (document.querySelector("#telegram").value.trim().length > 0) {
            telegram = {
                String: document.querySelector("#telegram").value,
                Valid: true,
            }
        } else {
            telegram = {
                String: "",
                Valid: false,
            }
        }


        if (user.Login !== login) {
            let checkResult = await checkUniqueConcretelyData("login", login)

            if (!checkResult.Error) {
                if (!checkResult.Result) {
                    document.querySelector("#login").style.borderBottom = "1px solid #ff0000"
                    result = false
                } else {
                    document.querySelector("#login").style.borderBottom = "1px solid #e5e5e5";
                }
            }
        }
        if (user.Email.String !== email.String) {
            let checkResult = await checkUniqueConcretelyData("email", email.String)

            if (!checkResult.Error) {
                if (!checkResult.Result) {
                    document.querySelector("#email").style.borderBottom = "1px solid #ff0000"
                    result = false
                } else {
                    document.querySelector("#email").style.borderBottom = "1px solid #e5e5e5";
                }
            }
        }
        if (user.Phone.String !== phone.String) {
            let checkResult = await checkUniqueConcretelyData("phone", phone.String)

            if (!checkResult.Error) {
                if (!checkResult.Result) {
                    document.querySelector("#phone").style.borderBottom = "1px solid #ff0000"
                    result = false
                } else {
                    document.querySelector("#phone").style.borderBottom = "1px solid #e5e5e5";
                }
            }
        }
        if (user.TelegramUsername.String !== telegram.String) {
            let checkResult = await checkUniqueConcretelyData("telegram", telegram.String)

            if (!checkResult.Error) {
                if (!checkResult.Result) {
                    document.querySelector("#telegram").style.borderBottom = "1px solid #ff0000"
                    result = false
                } else {
                    document.querySelector("#telegram").style.borderBottom = "1px solid #e5e5e5";
                }
            }
        }

        if (result) {
            return {
                ...user,
                Name: name,
                Login: login,
                Password: password,
                Phone: phone,
                Email: email,
                TelegramUsername: telegram,
                FlagNewTaskNotification: document.querySelector("#flag_new_task").checked,
                FlagCloseTaskNotification: document.querySelector("#flag_close_task").checked,
                FlagExpiringTaskNotification: document.querySelector("#flag_expiring_task").checked,
                FlagCompleteTaskNotification: document.querySelector("#flag_complete_task").checked,
                FlagProcessingTaskNotification: document.querySelector("#flag_processing_task").checked,
                FlagEditTaskNotification: document.querySelector("#flag_edit_task").checked,
                FlagExpiredTaskNotification: document.querySelector("#flag_expired_task").checked,
            }
        } else {
            return false
        }
    }

    const handlerConfirmChange = async () => {
        let sendData = await validateData()

        if (sendData) {
            setSendBody(sendData)
            setIsOpenConfirmWindow(true)
        }
    }

    const handlerSaveChange = () => {
        FetchRequest("POST", "/edit_profile", sendBody)
            .then(response => {
                if (response.success) {
                    setUser(response.data)
                }
            })
        if (sendBody.Password.trim().length > 0) {
            localStorage.removeItem("token")
            window.location.href = "/login"
        }
        setIsOpenConfirmWindow(false)
        setSendBody(null)
        setViewMode("preview")
    }

    const handlerChangeSlide = () => {
        setSliderButtonLeft(!sliderButtonLeft)
        setSliderButtonRight(!sliderButtonRight)
    }

    return (
        <section className={"profile-page"}>
            <div className="head">
                <div className="block">
                    <div className="icon"><FontAwesomeIcon icon={faUsers} /></div>
                    <div className="text">
                        <div><Link to={"/profile"}>Профиль</Link> /</div>
                        <h1>Профиль</h1>
                    </div>
                </div>
            </div>
            {isLoaded ?
                <div className="profile-view">
                    <div className="profile-left">
                        <div className="avatar-contain">
                            <div className="img">
                                <div className="icon" title={"В РАЗРАБОТКЕ"} onClick={() => alert("Функция в разработке")}><FontAwesomeIcon icon={faPen} /></div>
                                <img src="/logo192.png" alt=""/>
                            </div>
                            <h3>{user.Name.split(" ").length > 2 ? user.Name.split(" ")[0] +" "+ user.Name.split(" ")[1] : user.Name}</h3>
                            <p>{user.Login}</p>
                        </div>
                        <div className="slider">
                            {sliderButtonLeft && <div className="slider-button left" onClick={handlerChangeSlide}><FontAwesomeIcon icon={faChevronLeft} /></div>}
                            {sliderButtonRight && <div className="slider-button right" onClick={handlerChangeSlide}><FontAwesomeIcon icon={faChevronRight} /></div>}

                            <div className={sliderButtonRight ? "slide one active" : "slide one"}>
                                <div className="statistic-contain">
                                    <div className="bar">
                                        <div className="color bg-blue"></div>
                                        <div className="amount">{tasksStatistics[0]}</div>
                                        <div className="text">Текущее количество задач</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-red"></div>
                                        <div className="amount">{tasksStatistics[3]}</div>
                                        <div className="text">Количество выполненных задач</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-purple"></div>
                                        <div className="amount">{tasksStatistics[2]}</div>
                                        <div className="text">Количество просроченых задач</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-green"></div>
                                        <div className="amount">{tasksStatistics[1]}</div>
                                        <div className="text">Количество созданых задач</div>
                                    </div>
                                </div>
                                <div className="pie-contain">
                                    <Pie data={{
                                        labels: ['Созданно задач', 'Выполненно задач', 'Просрочено задач'],
                                        datasets: [
                                            {
                                                label: 'Кол-во',
                                                data: [tasksStatistics[1], tasksStatistics[3], tasksStatistics[2]],
                                                backgroundColor: [
                                                    'rgb(78, 203, 132)',
                                                    'rgb(238, 80, 55)',
                                                    'rgb(170, 0, 255)',
                                                ],
                                                borderColor: [
                                                    'rgb(78, 203, 132)',
                                                    'rgb(238, 80, 55)',
                                                    'rgb(170, 0, 255)',
                                                ],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }}/>
                                </div>
                            </div>

                            <div className={sliderButtonLeft ? "slide two active" : "slide two"}>
                                <div className="statistic-contain">
                                    <div className="bar">
                                        <div className="color bg-blue"></div>
                                        <div className="amount">{projectStatistics[0]}</div>
                                        <div className="text">Текущее количество проектов</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-red"></div>
                                        <div className="amount">{projectStatistics[3]}</div>
                                        <div className="text">Количество выполненных проектов</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-purple"></div>
                                        <div className="amount">{projectStatistics[2]}</div>
                                        <div className="text">Количество просроченых проектов</div>
                                    </div>
                                    <div className="bar">
                                        <div className="color bg-green"></div>
                                        <div className="amount">{projectStatistics[1]}</div>
                                        <div className="text">Количество созданых проектов</div>
                                    </div>
                                </div>
                                <div className="pie-contain">
                                    <Pie data={{
                                        labels: ['Созданно проектов', 'Выполненно проектов', 'Просрочено проектов'],
                                        datasets: [
                                            {
                                                label: 'Кол-во',
                                                data: [projectStatistics[1], projectStatistics[3], projectStatistics[2]],
                                                backgroundColor: [
                                                    'rgb(78, 203, 132)',
                                                    'rgb(238, 80, 55)',
                                                    'rgb(170, 0, 255)',
                                                ],
                                                borderColor: [
                                                    'rgb(78, 203, 132)',
                                                    'rgb(238, 80, 55)',
                                                    'rgb(170, 0, 255)',
                                                ],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {viewMode === "preview" ?
                        <div className="profile-right">
                            <div className="selector-info">
                                <h4>Неизменяемая информация</h4>
                                <div className="blocks">
                                    <div className="info-block">
                                        <span className="header">Роль:</span>
                                        <span className={"span bg-"+user.Role.Color}>{user.Role.TranslateValue}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Отдел:</span>
                                        <span className={"span bg-"+user.Department.Color}>{user.Department.TranslateValue}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Пол:</span>
                                        <span className={"span bg-"+user.Gender.Color}>{user.Gender.TranslateValue}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-info">
                                <div className="user-data">
                                    <h4>Общая информация</h4>
                                    <div className="info-block">
                                        <span className="header">ФИО:</span>
                                        <span>{user.Name}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Логин:</span>
                                        <span>{user.Login}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Телефон:</span>
                                        <span>{user.Phone.Valid ? user.Phone.String : "-"}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">E-mail:</span>
                                        <span>{user.Email.Valid ? user.Email.String : "-"}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Telegram:</span>
                                        <span>{user.TelegramUsername.Valid ? user.TelegramUsername.String : "-"}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Пароль:</span>
                                        <span>•••••</span>
                                    </div>
                                </div>
                                <div className="telegram-data">
                                    <h4>Telegram Бот</h4>
                                    <div className="info-block">
                                        <span className="header">Статус:</span>
                                        {user.TelegramChatID.Valid ? <span className="span bg-green">Подключен</span> :
                                            <span className="span bg-red">Не подключен</span>}
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Уведомлять меня о:</span>
                                        <div className="checkbox-list">
                                            <label>
                                                <div className={user.FlagNewTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>новых задачах, назначенных на меня</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagProcessingTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>принятии в обработку, созданных мною задач</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagCloseTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>закрытии моих задач другими пользователями</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagExpiringTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>истечении срока, назначенных на меня задач</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagCompleteTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>выполнении, созданных мною задач</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagExpiredTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>невыполнении в срок, созданых мною задач</span>
                                            </label>
                                            <label>
                                                <div className={user.FlagEditTaskNotification ? "checkbox active" : "checkbox"}>
                                                    <div></div>
                                                </div>
                                                <span>изменении, назначенных на меня задач</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btns">
                                <button className="bg-blue" onClick={() => setViewMode("edit")}>Изменить</button>
                            </div>
                        </div>
                    :
                        <div className="profile-right">
                            {isOpenConfirmWindow && <ConfirmWindow text="Вы уверены, что хотите сохранить изменения?" confirmFunction={() => handlerSaveChange()} setWindowState={setIsOpenConfirmWindow} /> }
                            <div className="selector-info">
                                <h4>Неизменяемая информация</h4>
                                <div className="blocks">
                                    <div className="info-block">
                                        <span className="header">Роль:</span>
                                        <span className={"span bg-"+user.Role.Color}>{user.Role.TranslateValue}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Отдел:</span>
                                        <span className={"span bg-"+user.Department.Color}>{user.Department.TranslateValue}</span>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Пол:</span>
                                        <span className={"span bg-"+user.Gender.Color}>{user.Gender.TranslateValue}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-info edit">
                                <div className="user-data">
                                    <h4>Общая информация</h4>
                                    <div className="info-block">
                                        <span className="header">ФИО:</span>
                                        <input type="text" defaultValue={user.Name} id="name" />
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Логин:</span>
                                        <input type="text" defaultValue={user.Login} id="login" />
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Телефон:</span>
                                        <input type="text" defaultValue={user.Phone.Valid ? user.Phone.String : ""} id="phone"/>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">E-mail:</span>
                                        <input type="text" defaultValue={user.Email.Valid ? user.Email.String : ""} id="email"/>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Telegram:</span>
                                        <input type="text" defaultValue={user.TelegramUsername.Valid ? user.TelegramUsername.String : "-"} id="telegram"/>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Пароль:</span>
                                        <input type="password" id="password"/>
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Пароль ещё раз:</span>
                                        <input type="password" id="password_confirm"/>
                                    </div>
                                </div>
                                <div className="telegram-data">
                                    <h4>Telegram Бот</h4>
                                    <div className="info-block">
                                        <span className="header">Статус:</span>
                                        {user.TelegramChatID.Valid ? <span className="span bg-green">Подключен</span> :
                                            <span className="span bg-red">Не подключен</span>}
                                    </div>
                                    <div className="info-block">
                                        <span className="header">Уведомлять меня о:</span>
                                        <div className="checkbox-list">
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagNewTaskNotification ? true : false} id="flag_new_task"/>
                                                <span>новых задачах, назначенных на меня</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagProcessingTaskNotification ? true : false} id="flag_processing_task"/>
                                                <span>принятии в обработку, созданных мною задач</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagCloseTaskNotification ? true : false} id="flag_close_task"/>
                                                <span>закрытии моих задач другими пользователями</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagExpiringTaskNotification ? true : false} id="flag_expiring_task"/>
                                                <span>истечении срока, назначенных на меня задач</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagCompleteTaskNotification ? true : false} id="flag_complete_task"/>
                                                <span>выполнении, созданных мною задач</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagExpiredTaskNotification ? true : false} id="flag_expired_task"/>
                                                <span>невыполнении в срок, созданных мною задач</span>
                                            </label>
                                            <label>
                                                <input type="checkbox" defaultChecked={user.FlagEditTaskNotification ? true : false} id="flag_edit_task"/>
                                                <span>изменении, назначенных на меня задач</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btns">
                                <button className="bg-green" onClick={handlerConfirmChange}>Сохранить</button>
                                <button className="bg-red" onClick={() => setViewMode("preview")}>Отмена</button>
                            </div>
                        </div>
                    }
                </div>
            :
                <Preload />
            }
        </section>
    )
}

export default ProfilePage