import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";

const TableSwitchHeaders = ({type, handlerSort}) => {
    const [headers, setHeaders] = useState([])

    useEffect(() => {
        switch (type) {
            case "users":
            case "select-users":
                setHeaders([
                    <th key={"header-"+type+"-"+1} className={"col1"} onClick={() => handlerSort("number", "ID", null)}><div><span>ID</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+2} className={"col2"} onClick={() => handlerSort("string", "Role", "Enum")}><div><span>Роль</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+3} className={"col3"} onClick={() => handlerSort("string", "Login", null)}><div><span>Логин</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+4} className={"col4"} onClick={() => handlerSort("string", "Name", null)}><div><span>ФИО</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+5} className={"col5"} onClick={() => handlerSort("string", "Gender", "Enum")}><div><span>Пол</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+6} className={"col6"} onClick={() => handlerSort("string", "Phone", "String")}><div><span>Телефон</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+7} className={"col7"} onClick={() => handlerSort("string", "Email", "String")}><div><span>E-mail</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+8} className={"col8"} onClick={() => handlerSort("string", "Baned", "Enum")}><div><span>Статус</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+9} className={"col9"}></th>,
                ]); break;
            case "tasks":
            case "projects":
            case "Projects":
            case "Tasks":
                setHeaders([
                    <th key={"header-"+type+"-"+1} className={"col1"} onClick={() => handlerSort("number", "ID", null)}><div><span>ID</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+2} className={"col2"} onClick={() => handlerSort("importance", "Priority", "Enum")}><div><span>Приоритет</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+3} className={"col3"} onClick={() => handlerSort("string", "Name", null)}><div><span>Краткое описание</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+4} className={"col4"} onClick={() => handlerSort("string", "ExecutiveDepartment", "Enum")}><div><span>Исполнительный отдел</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+5} className={"col5"} onClick={() => handlerSort("string", "Executor", "User")}><div><span>Исполнитель</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+6} className={"col6"} onClick={() => handlerSort("number", "PeriodExecution", "Int64")}><div><span>Крайний срок</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+7} className={"col7"} onClick={() => handlerSort("string", "Creator", "User")}><div><span>Создатель</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+8} className={"col8"} onClick={() => handlerSort("number", "CreatedAt", null)}><div><span>Дата создания</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+9} className={"col9"} onClick={() => handlerSort("string", "Status", "Enum")}><div><span>Статус</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+10} className={"col10"}></th>,
                ]); break;
            case "events":
            case "concretelyEvents":
                let columns
                if (type === "events") {
                    columns = [
                        <th key={"header-"+type+"-"+1} className={"col1"} onClick={() => handlerSort("string", "User", "User")}><div><span>Пользователь</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+2} className={"col2"}><div><span>Тип записи</span><span className={"table-head-arrows"}></span></div></th>,
                        <th key={"header-"+type+"-"+3} className={"col3"}><div><span>Запись</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+4} className={"col4"}><div><span>Событие</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+5} className={"col5"} onClick={() => handlerSort("number", "Date", null)}><div><span>Дата</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    ]
                } else {
                    columns = [
                        <th key={"header-"+type+"-"+1} className={"col1"} onClick={() => handlerSort("number", "ID", null)}><div><span>ID</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+2} className={"col2"} onClick={() => handlerSort("string", "User", "User")}><div><span>Пользователь</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+3} className={"col3"}><div><span>Запись</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+4} className={"col4"}><div><span>Событие</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                        <th key={"header-"+type+"-"+5} className={"col5"} onClick={() => handlerSort("number", "Date", null)}><div><span>Дата</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    ]
                }
                setHeaders(columns); break;
            case "notifications":
                setHeaders([
                    <th key={"header-"+type+"-"+1} className={"col1"} onClick={() => handlerSort("number", "ID", null)}><div><span>ID</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+2} className={"col2"} onClick={() => handlerSort("string", "Text", null)}><div><span>Текст</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+3} className={"col3"} onClick={() => handlerSort("bool", "TypeNotification", null)}><div><span>Тип</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+4} className={"col4"} onClick={() => handlerSort("number", "DateSend", null)}><div><span>Дата отправки</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+5} className={"col5"} onClick={() => handlerSort("string", "Creator", "User")}><div><span>Создатель</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+6} className={"col6"} onClick={() => handlerSort("number", "LastSend", "Int64")}><div><span>Последняя отправка</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+7} className={"col7"} onClick={() => handlerSort("string", "Status", "Enum")}><div><span>Статус</span><span className={"table-head-arrows"}><FontAwesomeIcon icon={faCaretUp} /><FontAwesomeIcon icon={faCaretDown} /></span></div></th>,
                    <th key={"header-"+type+"-"+8} className={"col8"}></th>,
                ]); break;
            default: setHeaders([])
        }
    }, [type, handlerSort])

    return (
        <tr className={"row-type-2"}>
            {headers}
        </tr>

    )
}

export default TableSwitchHeaders