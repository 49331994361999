import React, {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import Preload from "./Preload";
import API_DOMAIN from "../config";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Table from "./Table";
import UsersPageModalCreate from "./UsersPageModalCreate";
import ConfirmWindow from "./ConfirmWindow";
import Notification from "./Notification";
import FetchRequest from "../fetchRequest";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const UserViewPage = () => {
    const {userID} = useParams()
    const [user, setUser] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedTable, setIsLoadedTable] = useState(false)
    const [records, setRecords] = useState({
        ConfirmTasks: [],
        CreatedTasks: [],
        CurrentTasks: [],
        ConfirmProjects: [],
        CreatedProjects: [],
        CurrentProjects: [],
        Events: [],
    })
    const statisticsLabels = ({
        ConfirmTasks: "Выполненны задач за месяц",
        CreatedTasks: "Созданных задач за месяц",
        CurrentTasks: "",
        ConfirmProjects: "Выполненны проектов за месяц",
        CreatedProjects: "Созданных задач за месяц",
        CurrentProjects: "",
        Events: "Совершенных событий за месяц",
    })
    const [countStatistics, setCountStatistics] = useState({
        ConfirmTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
        CreatedTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
        CurrentTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
        ConfirmProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
        CreatedProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
        CurrentProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
        Events: [0,0,0,0,0,0,0,0,0,0,0,0]
    })
    const [selectedTable, setSelectedTable] = useState("ConfirmTasks")
    const [modalEdit, setModalEdit] = useState(false)
    const [confirmWindowBlock, setConfirmWindowBlock] = useState(false)
    const [isOpenConfirmAction, setIsOpenConfirmAction] = useState(false)
    const [confirmActionWindowText, setConfirmActionWindowText] = useState("")
    const [confirmActionWindowData, setConfirmActionWindowData] = useState({
        Record: null,
        Action: "",
    })

    const fetchRequest = useCallback(() => {
        FetchRequest("GET", `/get_user/${userID}`, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setUser(response.data)
                    } else {
                        setUser({})
                    }
                    setIsLoaded(true)
                }
            })
    }, [userID])

    useEffect(() => {
        FetchRequest("GET", `/get_user_table_records/${userID}`, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        let updatedRecords = {
                            ConfirmTasks: [],
                            CreatedTasks: [],
                            CurrentTasks: [],
                            ConfirmProjects: [],
                            CreatedProjects: [],
                            CurrentProjects: [],
                            Events: [],
                        }
                        let updatedCountStatistics = {
                            ConfirmTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
                            CreatedTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
                            CurrentTasks: [0,0,0,0,0,0,0,0,0,0,0,0],
                            ConfirmProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
                            CreatedProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
                            CurrentProjects: [0,0,0,0,0,0,0,0,0,0,0,0],
                            Events: [0,0,0,0,0,0,0,0,0,0,0,0]
                        }

                        if (response.data.ConfirmTasks != null) {
                            updatedRecords.ConfirmTasks = response.data.ConfirmTasks

                            for (let task of response.data.ConfirmTasks) {
                                let taskClosedAt = new Date(task.ClosedAt.Int64)
                                if (taskClosedAt.getFullYear() === new Date().getFullYear() ) {
                                    updatedCountStatistics.ConfirmTasks[taskClosedAt.getMonth()] += 1
                                }
                            }
                        }
                        if (response.data.CreatedTasks != null) {
                            updatedRecords.CreatedTasks = response.data.CreatedTasks

                            for (let task of response.data.CreatedTasks) {
                                let taskClosedAt = new Date(task.CreatedAt)
                                if (taskClosedAt.getFullYear() === new Date().getFullYear() ) {
                                    updatedCountStatistics.CreatedTasks[taskClosedAt.getMonth()] += 1
                                }
                            }
                        }
                        if (response.data.CurrentTasks != null) {
                            updatedRecords.CurrentTasks = response.data.CurrentTasks
                        }
                        if (response.data.CurrentProjects != null) {
                            updatedRecords.CurrentProjects = response.data.CurrentProjects
                        }
                        if (response.data.ConfirmProjects != null) {
                            updatedRecords.ConfirmProjects = response.data.ConfirmProjects

                            for (let project of response.data.ConfirmProjects) {
                                let projectClosedAt = new Date(project.ClosedAt.Int64)
                                if (projectClosedAt.getFullYear() === new Date().getFullYear() ) {
                                    updatedCountStatistics.ConfirmProjects[projectClosedAt.getMonth()] += 1
                                }
                            }
                        }
                        if (response.data.CreatedProjects != null) {
                            updatedRecords.CreatedProjects = response.data.CreatedProjects

                            for (let project of response.data.CreatedProjects) {
                                let projectCreatedAt = new Date(project.CreatedAt)
                                if (projectCreatedAt.getFullYear() === new Date().getFullYear() ) {
                                    updatedCountStatistics.CreatedProjects[projectCreatedAt.getMonth()] += 1
                                }
                            }
                        }
                        if (response.data.Events != null) {
                            updatedRecords.Events = response.data.Events.sort(function (event1, event2) {
                                return event2.Date - event1.Date
                            })

                            for (let event of response.data.Events) {
                                let eventDate = new Date(event.Date)
                                if (eventDate.getFullYear() === new Date().getFullYear() ) {
                                    updatedCountStatistics.Events[eventDate.getMonth()] += 1
                                }
                            }
                        }

                        setRecords(updatedRecords)
                        setCountStatistics(updatedCountStatistics)
                    }
                    setIsLoadedTable(true)
                }
            })
    }, [user, userID])

    useEffect(() => {
        fetchRequest()
    }, [userID, fetchRequest])

    const handlerSwitchStatusUser = () => {
        FetchRequest("POST", "/switch_status_user", user)
            .then(response => {
                if (response.success) {
                    setConfirmWindowBlock(false)
                    setUser(prevState => ({...prevState, Baned: response.data.Baned}))
                }
            })
    }

    const taskAction = (record, typeAction) => {
        setIsOpenConfirmAction(false)
        setConfirmActionWindowText("")
        setConfirmActionWindowData({
            Record: null,
            Action: "",
        })
        setIsLoaded(false)

        let url, type
        switch (selectedTable) {
            case "ConfirmTasks":
            case "CreatedTasks":
            case "CurrentTasks":
                type = "task"; break
            case "ConfirmProjects":
            case "CreatedProjects":
            case "CurrentProjects":
                type = "project"; break
            default: return
        }

        switch (typeAction) {
            case type+"-accept": url = `/${type}_action/accept`; break;
            case type+"-close": url = `/${type}_action/close`; break;
            case type+"-reopen": url = `/${type}_action/reopen`; break;
            default:
                url = ""
        }

        FetchRequest("POST", url, record)
            .then(response => {
                if (response.success) {
                    let updatedRecords = {...records}
                    if (typeAction === "task-accept") {
                        let indexUpdatedRecord = updatedRecords.CreatedTasks.findIndex(obj => {
                            return obj.ID === record.ID
                        })

                        updatedRecords.CreatedTasks[indexUpdatedRecord].Executor = response.data.Executor
                        updatedRecords.CreatedTasks[indexUpdatedRecord].IsClosed = response.data.IsClosed
                        updatedRecords.CreatedTasks[indexUpdatedRecord].CurrentUserIsOwner = response.data.CurrentUserIsOwner
                    } else if (typeAction === "project-accept") {
                        let indexUpdatedRecord = updatedRecords.CreatedProjects.findIndex(obj => {
                            return obj.ID === record.ID
                        })

                        updatedRecords.CreatedProjects[indexUpdatedRecord].Executor = response.data.Executor
                        updatedRecords.CreatedProjects[indexUpdatedRecord].IsClosed = response.data.IsClosed
                        updatedRecords.CreatedProjects[indexUpdatedRecord].CurrentUserIsOwner = response.data.CurrentUserIsOwner
                    } else {
                        setIsLoaded(false)
                        setIsLoadedTable(false)
                        fetchRequest()
                        return
                    }

                    setRecords(updatedRecords)
                }
            })
    }
    
    const handlerConfirmAction = (record, action) => {
        let text

        switch (action) {
            case "task-accept": text = "Вы уверены, что хотите принять задачу в обработку?"; break;
            case "task-close": text = "Вы уверены, что хотите закрыть задачу?"; break;
            case "task-reopen": text = "Вы уверены, что хотите переоткрыть задачу?"; break;
            case "project-accept": text = "Вы уверены, что хотите принять проект в обработку?"; break;
            case "project-close": text = "Вы уверены, что хотите закрыть проект?"; break;
            case "project-reopen": text = "Вы уверены, что хотите переоткрыть проект?"; break;
            default:
                text = ""
        }

        setIsOpenConfirmAction(true)
        setConfirmActionWindowText(text)
        setConfirmActionWindowData({
            Record: record,
            Action: action
        })
    }

    const handlerApplyChangesUser = (user) => {
        setUser(user)
    }

    return (
        <section className={"users-view-page"}>
            <div className="head">
                <div className="block">
                    <div className="icon"><FontAwesomeIcon icon={faUsers} /></div>
                    <div className="text">
                        <div><Link to={"/users"}>Пользователи</Link> / <Link to={"/users/view/"+userID}>Пользователь: {userID}</Link></div>
                        <h1>{user.Name}</h1>
                    </div>
                </div>
                <Notification />
            </div>
            {confirmWindowBlock && <ConfirmWindow text={user.Baned.Value ? "Вы уверены, что хотите разблокировать этого пользователя?"
                : "Вы уверены, что хотите заблокировать этого пользователя?"}
                confirmFunction={handlerSwitchStatusUser}
                setWindowState={setConfirmWindowBlock}/>}
            {isOpenConfirmAction && <ConfirmWindow text={confirmActionWindowText} confirmFunction={() => taskAction(confirmActionWindowData.Record, confirmActionWindowData.Action)} setWindowState={setIsOpenConfirmAction} /> }
            {modalEdit && <UsersPageModalCreate addUser={handlerApplyChangesUser} setModalCreate={setModalEdit} action={"edit"} editUser={user}/>}
            {isLoaded ?
                <div className="users-view view-page">
                    <div className="left-side">
                        <div className="contain-status">
                                {(localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") &&
                                    <div className="buttons">
                                        <button className="bg-blue" onClick={() => {
                                            setModalEdit(true)}
                                        }>Изменить</button>
                                        <button className="bg-blue" onClick={() => setConfirmWindowBlock(true)}>{user.Baned.Value ? "Разблокировать" : "Заблокировать"}</button>
                                    </div>
                                }
                            <div className="bottom">
                                <h3>Статус:</h3>
                                <div className="status">
                                    <div className={user.Baned.Value ? "bg-red" : "bg-green"}>{user.Baned.TranslateValue}</div>
                                </div>
                            </div>
                        </div>
                        <div className="contain-info">
                            <h3>Информация</h3>
                            <div className="info-block">
                                <span className="header">Логин:</span>
                                <span>{user.Login}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Роль:</span>
                                <span className={"span bg-"+user.Role.Color}>{user.Role.TranslateValue}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">ФИО:</span>
                                <span>{user.Name}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Отдел:</span>
                                <span className={"span bg-"+user.Department.Color}>{user.Department.TranslateValue}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Пол:</span>
                                <span>{user.Gender.TranslateValue}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Телефон:</span>
                                <span>{user.Phone.Valid ? user.Phone.String : "-"}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">E-mail:</span>
                                <span>{user.Email.Valid ? user.Email.String : "-"}</span>
                            </div>
                           <div className="row">
                               <div className="info-block">
                                   <span className="header">Telegram:</span>
                                   <span>{user.TelegramUsername.Valid ? user.TelegramUsername.String : "-"}</span>
                               </div>
                               <div className="info-block">
                                   <span className="header">Статус:</span>
                                   {user.TelegramChatID.Valid ? <span className="span bg-green">Подключен</span> :
                                       <span className="span bg-red">Не подключен</span>}
                               </div>
                           </div>
                            <div className="info-block">
                                <span className="header">Создан:</span>
                                <span>{new Date(user.CreatedAt).toLocaleString()}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Изменен:</span>
                                <span>{user.UpdatedAt === user.CreatedAt ? "-" : new Date(user.UpdatedAt).toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="table-contain">
                            <div className="sub-nav">
                                <span className={selectedTable === "ConfirmTasks" ? "active" : ""}
                                      onClick={() => setSelectedTable("ConfirmTasks")}>Выполненные задачи</span>
                                <span className={selectedTable === "CreatedTasks" ? "active" : ""}
                                      onClick={() => setSelectedTable("CreatedTasks")}>Созданные задачи</span>
                                <span className={selectedTable === "CurrentTasks" ? "active" : ""}
                                      onClick={() => setSelectedTable("CurrentTasks")}>Текущие задачи</span>
                                <span className={selectedTable === "ConfirmProjects" ? "active" : ""}
                                      onClick={() => setSelectedTable("ConfirmProjects")}>Выполенные проекты</span>
                                <span className={selectedTable === "CreatedProjects" ? "active" : ""}
                                      onClick={() => setSelectedTable("CreatedProjects")}>Созданные проекты</span>
                                <span className={selectedTable === "CurrentProjects" ? "active" : ""}
                                      onClick={() => setSelectedTable("CurrentProjects")}>Текущие проекты</span>
                                <span className={selectedTable === "Events" ? "active" : ""}
                                      onClick={() => setSelectedTable("Events")}>События</span>
                            </div>
                            {isLoadedTable && selectedTable === "ConfirmTasks" ? <Table records={records.ConfirmTasks} type={"tasks"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "CreatedTasks" ? <Table records={records.CreatedTasks} type={"tasks"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "CurrentTasks" ? <Table records={records.CurrentTasks} type={"tasks"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "ConfirmProjects" ? <Table records={records.ConfirmProjects} type={"projects"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "CreatedProjects" ? <Table records={records.CreatedProjects} type={"projects"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "CurrentProjects" ? <Table records={records.CurrentProjects} type={"projects"} additionalClass={"view user"} onClick3thIcon={handlerConfirmAction}/>
                                : isLoadedTable && selectedTable === "Events" ? <Table records={records.Events} type={"events"} additionalClass={"view user"}/> : ""
                            }
                        </div>
                        <div className="chart">
                            <Bar height={270} width={1000}
                                 data={{
                                     labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь',
                                         'Октябрь', 'Ноябрь', 'Декабрь'],
                                     datasets: [
                                         {
                                             label: statisticsLabels[selectedTable],
                                             data: [...countStatistics[selectedTable]],
                                             backgroundColor: 'rgb(2,115,245)',
                                         },
                                     ],
                                 }}/>
                        </div>
                    </div>
                </div>
                : <Preload />
            }
        </section>
    )
}

export default UserViewPage