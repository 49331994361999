import React, {useEffect, useState} from "react";
import Table from "./Table";
import FetchRequest from "../fetchRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Preload from "./Preload";

const SelectTable = ({uri, type, selectedRecords, setSelectedRecords, setModalOpen, allRecords}) => {
    const [records, setRecords] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [activeTable, setActiveTable] = useState("Tasks")

    const handlerModalCreateClose = (e) => {
        if (e.target.className === "modal-table") {
            setModalOpen(false)
        }
    }

    useEffect(() => {
        if (uri != null) {
            FetchRequest("GET", uri, null)
                .then(response => {
                    if (response.success) {
                        if (type === "select-users") {
                            if (selectedRecords.Users.length > 0) {
                                let correctedRecords = []

                                for (let user of response.data) {
                                    let valid = true

                                    for (let selectedUser of selectedRecords.Users) {
                                        if (selectedUser.ID === user.ID) {
                                            valid = false
                                            break
                                        }
                                    }

                                    if (valid) {
                                        correctedRecords.push(user)
                                    }
                                }

                                setRecords(correctedRecords)
                            } else {
                                setRecords(response.data)
                            }
                        } else if (type === "select-tasks-projects") {
                            let correctedRecords = {
                                Tasks: [],
                                Projects: [],
                            }

                            if (selectedRecords.Tasks.length > 0) {
                                for (let task of response.data.Tasks) {
                                    let valid = true

                                    for (let selectedTask of selectedRecords.Tasks) {
                                        if (selectedTask.ID === task.ID) {
                                            valid = false
                                            break
                                        }
                                    }

                                    if (valid) {
                                        correctedRecords.Tasks.push(task)
                                    }
                                }

                            } else if (response.data.Tasks != null)  {
                                correctedRecords.Tasks = response.data.Tasks
                            }
                            if (selectedRecords.Projects.length > 0) {
                                for (let project of response.data.Projects) {
                                    let valid = true

                                    for (let selectedProject of selectedRecords.Projects) {
                                        if (selectedProject.ID === project.ID) {
                                            valid = false
                                            break
                                        }
                                    }

                                    if (valid) {
                                        correctedRecords.Projects.push(project)
                                    }
                                }

                            } else if (response.data.Projects != null) {
                                correctedRecords.Projects = response.data.Projects
                            }

                            setRecords(correctedRecords)
                        }
                        setIsLoaded(true)
                    } else {
                        setRecords([])
                    }
                })
        } else {
            setRecords(allRecords)

            if (selectedRecords.Users.length > 0) {
                let correctedRecords = []

                for (let user of allRecords) {
                    let valid = true

                    for (let selectedUser of selectedRecords.Users) {
                        if (selectedUser.ID === user.ID) {
                            valid = false
                            break
                        }
                    }

                    if (valid) {
                        correctedRecords.push(user)
                    }
                }

                setRecords(correctedRecords)
            } else {
                setRecords(allRecords)
            }

            setIsLoaded(true)
        }
    }, [uri])

    const handlerSelectRecord = (record) => {
        if (type === "select-users") {
            setSelectedRecords(prevState => ({
                Users: [...prevState.Users, record],
                IsSelectAllUsers: false
            }))

            setRecords(prevState => prevState.filter(rec => rec.ID !== record.ID))
        } else {
            if (activeTable === "Tasks") {
                setSelectedRecords(prevState => ({
                    ...prevState,
                    Tasks: [...prevState.Tasks, record]
                }))

                setRecords(prevState => ({
                    ...prevState,
                    Tasks: prevState.Tasks.filter(rec => rec.ID !== record.ID)
                }))
            } else {
                setSelectedRecords(prevState => ({
                    ...prevState,
                    Projects: [...prevState.Projects, record]
                }))

                setRecords(prevState => ({
                    ...prevState,
                    Projects: prevState.Projects.filter(rec => rec.ID !== record.ID)
                }))
            }
        }
    }

    useEffect(() => {
        if ("Users" in selectedRecords) {
            setSelectedRecords(prevState => ({
                Users: [...prevState.Users],
                IsSelectAllUsers: records.length === 0 && selectedRecords.Users.length > 0
            }))
        } else if ("Tasks" in records) {
            let correctedRecord = {...selectedRecords}

            if (records.Tasks != null) {
                correctedRecord.IsSelectAllTasks = records.Tasks.length === 0 && correctedRecord.Tasks.length > 0;
            }
            if (records.Projects != null) {
                correctedRecord.IsSelectAllProjects = records.Projects.length === 0 && correctedRecord.Projects.length > 0;
            }


            setSelectedRecords(correctedRecord)
        }
    }, [records])

    const handlerSelectAll = () => {
        if (type === "select-users") {
            setSelectedRecords(prevState => ({
                Users: [...prevState.Users, ...records],
                IsSelectAllUsers: true,
            }))

            setRecords([])
        } else {
            if (activeTable === "Tasks") {
                setSelectedRecords(prevState => ({
                    ...prevState,
                    Tasks: [...prevState.Tasks, ...records.Tasks],
                    IsSelectAllTasks: true,
                }))

                setRecords(prevState => ({
                    ...prevState,
                    Tasks: [],
                }))
            } else {
                setSelectedRecords(prevState => ({
                    ...prevState,
                    Projects: [...prevState.Projects, ...records.Projects],
                    IsSelectAllProjects: true,
                }))

                setRecords(prevState => ({
                    ...prevState,
                    Projects: [],
                }))
            }
        }
    }

    const handlerClearSelect = () => {
        if (type === "select-users") {
            setRecords(prevState => [...prevState, ...selectedRecords.Users])
            setSelectedRecords({
                Users: [],
                IsSelectAllUsers: false,
            })
        } else {
            setRecords(prevState => ({
                ...prevState,
                Tasks: [...prevState.Tasks, ...selectedRecords.Tasks],
                Projects: [...prevState.Projects, ...selectedRecords.Projects]
            }))

            setSelectedRecords({
                Projects: [],
                Tasks: [],
                IsSelectAllTasks: false,
                IsSelectAllProjects: false
            })
        }
    }

    const handlerDeleteSelectRecord = (record, mainArray) => {
        if (type === "select-users") {
            setRecords(prevState => [...prevState, record])
            setSelectedRecords(prevState => ({
                Users: prevState.Users.filter(rec => rec.ID !== record.ID),
                IsSelectAllUsers: false
            }))
        } else {
            if (mainArray === "Tasks") {
                setRecords(prevState => ({
                    ...prevState,
                    Tasks: [...prevState.Tasks, record]
                }))

                setSelectedRecords(prevState => ({
                    ...prevState,
                    Tasks: prevState.Tasks.filter(rec => rec.ID !== record.ID),
                    IsSelectAllTasks: false
                }))
            } else {
                setRecords(prevState => ({
                    ...prevState,
                    Projects: [...prevState.Projects, record]
                }))

                setSelectedRecords(prevState => ({
                    ...prevState,
                    Projects: prevState.Projects.filter(rec => rec.ID !== record.ID),
                    IsSelectAllProjects: false
                }))
            }
        }
    }

    const handlerClearConcreteField = (key) => {
        if (key === "Projects") {
            setRecords(prevState => ({
                ...prevState,
                Projects: [...selectedRecords.Projects]
            }))

            setSelectedRecords(prevState => ({
                ...prevState,
                Projects: [],
                IsSelectAllProjects: false,
            }))
        } else {
            setRecords(prevState => ({
                ...prevState,
                Tasks: [...selectedRecords.Tasks]
            }))

            setSelectedRecords(prevState => ({
                ...prevState,
                Tasks: [],
                IsSelectAllTasks: false,
            }))
        }
    }

    return (
        <div className="modal-table" onMouseDown={handlerModalCreateClose}>
            <div className="contain">
                <div className="select-part">
                    <div className="select-contain">
                        {type === "select-users" ?
                            selectedRecords.IsSelectAllUsers ?  <span className={"bg-gradient"} onClick={handlerClearSelect}>Все пользователи <FontAwesomeIcon icon={faXmark} /></span>
                                : selectedRecords.Users.map((record,  index) => (
                                <span key={"selected-record-"+index} className={"bg-"+record.Role.Color} onClick={() => handlerDeleteSelectRecord(record)}>{record.Name.split(" ").length === 3 ?
                                    `${record.Name.split(" ")[0]} ${record.Name.split(" ")[1][0]}. ${record.Name.split(" ")[2][0]}.` : record.Name} <FontAwesomeIcon icon={faXmark} /></span>
                            )) : ""
                        }

                        {type === "select-tasks-projects" ?
                            selectedRecords.IsSelectAllTasks ? <span className={"bg-gradient"} onClick={() => handlerClearConcreteField("Tasks")}>Все задачи <FontAwesomeIcon icon={faXmark} /></span>
                                : selectedRecords.Tasks.map((record,  index) => (
                                <span key={"selected-record-task-"+index} className={"bg-blue"} onClick={() => handlerDeleteSelectRecord(record, "Tasks")}>{record.Name} <FontAwesomeIcon icon={faXmark} /></span>
                                ))
                            : ""}
                        {type === "select-tasks-projects" ?
                            selectedRecords.IsSelectAllProjects ? <span className={"bg-gradient"} onClick={() => handlerClearConcreteField("Projects")}>Все проекты <FontAwesomeIcon icon={faXmark} /></span>
                                : selectedRecords.Projects.map((record,  index) => (
                                <span key={"selected-record-project-"+index} className={"bg-green"} onClick={() => handlerDeleteSelectRecord(record, "Projects")}>{record.Name} <FontAwesomeIcon icon={faXmark} /></span>
                            ))
                            : ""}
                    </div>
                    <div className="btns-contain">
                        <button className="bg-blue" onClick={() => setModalOpen(false)}>Готово</button>
                        {type === "select-users" && <button className="bg-blue" onClick={handlerSelectAll}>Выбрать всех пользователей</button>}
                        {type === "select-tasks-projects" && <button className="bg-blue" onClick={handlerSelectAll}>Выбрать все {activeTable === "Tasks" ? "задачи" : "проекты"}</button>}
                        <button className="bg-red" onClick={handlerClearSelect}>Очистить</button>
                    </div>
                </div>
                <div className={"table-contain "+type}>
                    {type === "select-tasks-projects" &&
                        <div className="sub-nav modal-sub-nav" style={{paddingBottom: "0"}}>
                            <span className={activeTable === "Tasks" ? "active" : ""} onClick={() => setActiveTable("Tasks")}>Задачи</span>
                            <span className={activeTable === "Projects" ? "active" : ""} onClick={() => setActiveTable("Projects")}>Проекты</span>
                        </div>}
                    {isLoaded ?
                        type === "select-users" ?
                            <Table records={records}
                                   type={type}
                                   additionalClass={"modal"}
                                   onClick3thIcon={handlerSelectRecord}
                            />
                            :
                            <Table records={records[activeTable]}
                                   type={activeTable}
                                   additionalClass={"modal"}
                                   onClick3thIcon={handlerSelectRecord}
                            />
                        :
                        <Preload />}
                </div>
            </div>
        </div>
    )
}

export default SelectTable