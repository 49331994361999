import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan,
    faCheck,
    faEye, faHand,
    faLock,
    faMicrochip,
    faPen, faPlay,
    faPlus,
    faRotate, faStop,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const TableSwitchRecords = ({type, record, index, onClick3thIcon}) => {
    const [showRecord, setShowRecord] = useState([])

    useEffect(() => {
        let userNameParts, shortUserName, shortCreatorName
        switch (type) {
            case "users":
            case "select-users":
                let lastColumnUser

                if (type === "users") {
                    lastColumnUser = <td key={"sub-record-"+type+"-"+9} className={"col9"}>
                        <Link to={"/users/view/"+record.ID}><FontAwesomeIcon icon={faEye}/></Link>
                        {(localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") && <span title={"Изменить"} onClick={() => onClick3thIcon.EditUser(record)}><FontAwesomeIcon icon={faPen} /></span>}
                        {record.Baned.Value ? (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") && <span title={"Разблокировать"} onClick={() => onClick3thIcon.SwitchStatus(record)}><FontAwesomeIcon icon={faCheck} /></span>
                            : (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") && <span title={"Заблокировать"} onClick={() => onClick3thIcon.SwitchStatus(record)}><FontAwesomeIcon icon={faBan} /></span>}
                    </td>
                } else {
                    lastColumnUser = <td key={"sub-record-"+type+"-"+9} className={"col9"} style={{display: "table-cell"}}>
                        <span title={"Добавить"} onClick={() => onClick3thIcon(record)}><FontAwesomeIcon icon={faPlus} /></span>
                    </td>
                }

                setShowRecord([
                    <td key={"sub-record-"+type+"-"+1} className={"col1"}><Link to={"/users/view/"+record.ID}>{record.ID}</Link></td>,
                    <td key={"sub-record-"+type+"-"+2} className={"col2"}><span className={"bg-"+record.Role.Color}>{record.Role.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+3} className={"col3"}><Link to={"/users/view/"+record.ID}>{record.Login}</Link></td>,
                    <td key={"sub-record-"+type+"-"+4} className={"col4"}><Link to={"/users/view/"+record.ID}>{record.Name}</Link></td>,
                    <td key={"sub-record-"+type+"-"+5} className={"col5"}>{record.Gender.TranslateValue}</td>,
                    <td key={"sub-record-"+type+"-"+6} className={"col6"}>{record.Phone.String}</td>,
                    <td key={"sub-record-"+type+"-"+7} className={"col7"}>{record.Email.String}</td>,
                    <td key={"sub-record-"+type+"-"+8} className={"col8"}><span className={"bg-"+record.Baned.Color}>{record.Baned.TranslateValue}</span></td>,
                    lastColumnUser
                ]); break;
            case "tasks":
            case "Tasks":
                let lastColumnTask

               if (type === "tasks") {
                   lastColumnTask = <td key={"sub-record-"+type+"-"+10} className={"col10"}>
                       {/*<Link title="Просмотреть" to={"/tasks/view/"+record.ID}><FontAwesomeIcon icon={faEye}/></Link>*/}
                       {((record.Status.Value === "new" || record.Status.Value === "expire") && !record.AcceptedAt.Valid && (record.Executor.ID <= 0 || record.CurrentUserIsOwner)) && <span title="Принять в обработку" onClick={() => onClick3thIcon.Action(record, "task-accept")}><FontAwesomeIcon icon={faMicrochip} /></span>}
                       {(record.Status.Value === "new" || record.Status.Value === "processing" || record.Status.Value === "expire") && (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director" || record.CurrentUserIsCreator || (record.AcceptedAt.Valid && record.CurrentUserIsOwner && (record.Status.Value === "processing" || record.Status.Value === "expire"))) && <span title="Закрыть задачу" onClick={() => onClick3thIcon.Action(record, "task-close")}><FontAwesomeIcon icon={faLock} /></span>}
                       {record.IsClosed && record.Status.Value === "close" && <span title="Переоткрыть задачу" onClick={() => onClick3thIcon.Action(record, "task-reopen")}><FontAwesomeIcon icon={faRotate} /></span>}

                       {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director" || record.CurrentUserIsCreator) && <span title="Изменить задачу" onClick={() => onClick3thIcon.Edit(record, "task")}><FontAwesomeIcon icon={faPen} /></span>}
                       {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director") && record.Status.Value !== "delete" && <span title="Удалить задачу" onClick={() => onClick3thIcon.Action(record, "task-delete")}><FontAwesomeIcon icon={faTrash} /></span>}
                   </td>
               } else {
                   if (onClick3thIcon == null) {
                       lastColumnTask = <td key={"sub-record-"+type+"-"+10} className={"col10 none"}>
                       </td>
                   } else {
                       lastColumnTask = <td key={"sub-record-"+type+"-"+10} className={"col10"} style={{display: "table-cell"}}>
                           <span title={"Добавить"} onClick={() => onClick3thIcon(record)}><FontAwesomeIcon icon={faPlus} /></span>
                       </td>
                   }
               }

                userNameParts = record.Executor.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortUserName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortUserName = record.Executor.Name
                }

                userNameParts = record.Creator.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortCreatorName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortCreatorName = record.Creator.Name
                }

                setShowRecord([
                    <td key={"sub-record-"+type+"-"+1} className={"col1"}><Link title="Просмотреть" to={"/tasks/view/"+record.ID}>{record.ID}</Link>
                        {record.IsSubtask && <p className="bg-blue" title="Подзадача задачи"></p>}
                        {record.IsTaskOfProject && <p className="bg-green" title="Подзадача проекта"></p>}
                        {record.IncludesSubtasks > 0 && <p className="bg-red" title="Содержит подзадачи"></p>}
                    </td>,
                    <td key={"sub-record-"+type+"-"+2} className={"col2"}><span className={"bg-"+record.Priority.Color}>{record.Priority.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+3} className={"col3"}><Link title="Просмотреть" to={"/tasks/view/"+record.ID}>{record.Name}</Link></td>,
                    <td key={"sub-record-"+type+"-"+4} className={"col4"}><span className={"bg-"+record.ExecutiveDepartment.Color}>{record.ExecutiveDepartment.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+5} className={"col5"}>{record.Executor.ID > 0 ? <Link to={"/users/view/"+record.Executor.ID}>{shortUserName}</Link> : "-"}</td>,
                    <td key={"sub-record-"+type+"-"+6} className={"col6"}>{record.PeriodExecution.Valid ? new Date(record.PeriodExecution.Int64).toLocaleString().slice(0,10) : "-"}</td>,
                    <td key={"sub-record-"+type+"-"+7} className={"col7"}><Link to={"/users/view/"+record.Creator.ID}>{shortCreatorName}</Link></td>,
                    <td key={"sub-record-"+type+"-"+8} className={"col8"}>{new Date(record.CreatedAt).toLocaleString().slice(0,10)}</td>,
                    <td key={"sub-record-"+type+"-"+9} className={"col9"}><span className={"bg-"+record.Status.Color}>{record.Status.TranslateValue}</span></td>,
                    lastColumnTask,
                ]); break;
            case "projects":
            case "Projects":
                let lastColumnProject

                if (type === "projects") {
                    lastColumnProject = <td key={"sub-record-"+type+"-"+10} className={"col10"}>
                        {/*<Link title="Просмотреть" to={"/tasks/view/"+record.ID}><FontAwesomeIcon icon={faEye}/></Link>*/}
                        {((record.Status.Value === "new" || record.Status.Value === "expire") && !record.AcceptedAt.Valid && (record.Executor.ID <= 0 || record.CurrentUserIsOwner)) && <span title="Принять в обработку" onClick={() => onClick3thIcon.Action(record, "project-accept")}><FontAwesomeIcon icon={faMicrochip} /></span>}
                        {(record.Status.Value === "new" || record.Status.Value === "processing" || record.Status.Value === "expire") && (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director" || record.CurrentUserIsCreator || (record.AcceptedAt.Valid && record.CurrentUserIsOwner && (record.Status.Value === "processing" || record.Status.Value === "expire"))) && <span title="Закрыть проект" onClick={() => onClick3thIcon.Action(record, "project-close")}><FontAwesomeIcon icon={faLock} /></span>}
                        {record.IsClosed && record.Status.Value === "close" && <span title="Переоткрыть проект" onClick={() => onClick3thIcon.Action(record, "project-reopen")}><FontAwesomeIcon icon={faRotate} /></span>}

                        {((localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director") || record.CurrentUserIsCreator) && <span title="Изменить проект" onClick={() => onClick3thIcon.Edit(record, "project")}><FontAwesomeIcon icon={faPen} /></span>}
                        {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director") && record.Status.Value !== "delete" && <span title="Удалить проект" onClick={() => onClick3thIcon.Action(record, "project-delete")}><FontAwesomeIcon icon={faTrash} /></span>}
                    </td>
                } else {
                    if (onClick3thIcon == null) {
                        lastColumnProject = <td key={"sub-record-"+type+"-"+10} className={"col10 none"}>
                        </td>
                    } else {
                        lastColumnProject = <td key={"sub-record-"+type+"-"+10} className={"col10"} style={{display: "table-cell"}}>
                            <span title={"Добавить"} onClick={() => onClick3thIcon(record)}><FontAwesomeIcon icon={faPlus} /></span>
                        </td>
                    }
                }

                userNameParts = record.Executor.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortUserName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortUserName = record.Executor.Name
                }

                userNameParts = record.Creator.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortCreatorName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortCreatorName = record.Creator.Name
                }

                setShowRecord([
                    <td key={"sub-record-"+type+"-"+1} className={"col1"}><Link title="Просмотреть" to={"/projects/view/"+record.ID}>{record.ID}</Link>
                        {record.IncludesSubtasks > 0 && <p className="bg-red" title="Содержит подзадачи"></p>}
                    </td>,
                    <td key={"sub-record-"+type+"-"+2} className={"col2"}><span className={"bg-"+record.Priority.Color}>{record.Priority.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+3} className={"col3"}><Link title="Просмотреть" to={"/projects/view/"+record.ID}>{record.Name}</Link></td>,
                    <td key={"sub-record-"+type+"-"+4} className={"col4"}><span className={"bg-"+record.ExecutiveDepartment.Color}>{record.ExecutiveDepartment.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+5} className={"col5"}>{record.Executor.ID > 0 ? <Link to={"/users/view/"+record.Executor.ID}>{shortUserName}</Link> : "-"}</td>,
                    <td key={"sub-record-"+type+"-"+6} className={"col6"}>{record.PeriodExecution.Valid ? new Date(record.PeriodExecution.Int64).toLocaleString().slice(0,10) : "-"}</td>,
                    <td key={"sub-record-"+type+"-"+7} className={"col7"}><Link to={"/users/view/"+record.Creator.ID}>{shortCreatorName}</Link></td>,
                    <td key={"sub-record-"+type+"-"+8} className={"col8"}>{new Date(record.CreatedAt).toLocaleString().slice(0,10)}</td>,
                    <td key={"sub-record-"+type+"-"+9} className={"col9"}><span className={"bg-"+record.Status.Color}>{record.Status.TranslateValue}</span></td>,
                    lastColumnProject,
                ]); break;
            case "events":
            case "concretelyEvents":
                let parseRecord
                let typeRecord
                let columns
                switch (true) {
                    case 'Task' in record:
                        parseRecord = <Link to={"/tasks/view/"+record.Task.ID}>{record.Task.Name}</Link>;
                        typeRecord = "Задача"
                        break;
                    case 'Project' in record:
                        parseRecord = <Link to={"/projects/view/"+record.Project.ID}>{record.Project.Name}</Link>;
                        typeRecord = "Проект"
                        break;
                    case 'EditUser' in record:
                        let editUserNameParts = record.EditUser.Name.split(" ")
                        let shortEditUserName
                        if (editUserNameParts.length === 3) {
                            shortEditUserName = `${editUserNameParts[0]} ${editUserNameParts[1].slice(0,1)}.${editUserNameParts[2].slice(0,1)}.`
                        } else {
                            shortEditUserName = record.EditUser.Name
                        }
                        parseRecord = <Link to={"/users/view/"+record.EditUser.ID}>{shortEditUserName}({record.EditUser.Login})</Link>
                        typeRecord = "Пользователь"
                        break;
                    default:
                        parseRecord = <span className="error" key={"error"+1}>Ошибка</span>
                        typeRecord = <span className="error" key={"error"+2}>Ошибка</span>;
                }

                userNameParts = record.User.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortUserName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortUserName = record.User.Name
                }

                if (type === "events") {
                    columns = [
                        <td key={"sub-record-"+type+"-"+1} className={"col1"}><Link to={"/users/view/"+record.User.ID}>{shortUserName}({record.User.Login})</Link></td>,
                        <td key={"sub-record-"+type+"-"+2} className={"col2"}>{typeRecord}</td>,
                        <td key={"sub-record-"+type+"-"+3} className={"col3"}>{parseRecord}</td>,
                        <td key={"sub-record-"+type+"-"+4} className={"col4"}>{"Text" in record ? record.Text : record.Description}</td>,
                        <td key={"sub-record-"+type+"-"+5} className={"col5"}>{new Date(record.Date).toLocaleString()}</td>,
                    ]
                } else {
                    columns = [
                        <td key={"sub-record-"+type+"-"+1} className={"col1"}>{record.ID}</td>,
                        <td key={"sub-record-"+type+"-"+2} className={"col2"}><Link to={"/users/view/"+record.User.ID}>{shortUserName}({record.User.Login})</Link></td>,
                        <td key={"sub-record-"+type+"-"+3} className={"col3"}>{parseRecord}</td>,
                        <td key={"sub-record-"+type+"-"+4} className={"col4"}>{"Text" in record ? record.Text : record.Description}</td>,
                        <td key={"sub-record-"+type+"-"+5} className={"col5"}>{new Date(record.Date).toLocaleString()}</td>,
                    ]
                }

                setShowRecord(columns); break;
            case "notifications":
                userNameParts = record.Creator.Name.split(" ")
                if (userNameParts.length === 3) {
                    shortCreatorName = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
                } else {
                    shortCreatorName = record.Creator.Name
                }

                setShowRecord([
                    <td key={"sub-record-"+type+"-"+1} className={"col1"}>{record.ID}</td>,
                    <td key={"sub-record-"+type+"-"+2} className={"col2"}>{record.Text}</td>,
                    <td key={"sub-record-"+type+"-"+3} className={"col3"}>{record.IsCyclic ? "Цикличная" : "Разовая"}</td>,
                    <td key={"sub-record-"+type+"-"+4} className={"col4"}>{new Date(record.DateSend).toLocaleString().slice(0,17)}</td>,
                    <td key={"sub-record-"+type+"-"+5} className={"col5"}><Link to={"/users/view/"+record.Creator.ID}>{shortCreatorName}</Link></td>,
                    <td key={"sub-record-"+type+"-"+6} className={"col6"}>{record.LastSend.Valid ? new Date(record.LastSend.Int64).toLocaleString().slice(0,17) : "-"}</td>,
                    <td key={"sub-record-"+type+"-"+7} className={"col7"}><span className={"bg-"+record.Status.Color}>{record.Status.TranslateValue}</span></td>,
                    <td key={"sub-record-"+type+"-"+8} className={"col8"}>
                        <span title="Изменить уведомление" onClick={() => onClick3thIcon.Edit(record)}><FontAwesomeIcon icon={faPen} /></span>
                        {record.Status.Value === "wait_send" && <span title="Приостановить уведомление" onClick={() => onClick3thIcon.Action(record, "pause")}><FontAwesomeIcon icon={faStop} /></span>}
                        {record.Status.Value === "paused" && <span title="Возобновить уведомление" onClick={() => onClick3thIcon.Action(record, "unpause")}><FontAwesomeIcon icon={faPlay} /></span>}
                        <span title="Удалить уведомление" onClick={() => onClick3thIcon.Action(record, "delete")}><FontAwesomeIcon icon={faTrash} /></span>
                    </td>,
                ]); break;
            default: setShowRecord([])
        }
    }, [type, record, onClick3thIcon])

    return (
        <tr className={index % 2 === 0 ? 'row-type-1' : 'row-type-2'}>
            {showRecord}
        </tr>

    )
}

export default TableSwitchRecords