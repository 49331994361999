import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "./Table";
import Preload from "./Preload";
import {faDiagramProject, faListCheck} from "@fortawesome/free-solid-svg-icons";
import TaskModalCreate from "./TaskModalCreate";
import TaskChat from "./TaskChat";
import Notification from "./Notification";
import FetchRequest from "../fetchRequest";
import ConfirmWindow from "./ConfirmWindow";


const TaskViewPage = ({type}) => {
    const {taskID} = useParams()
    const {projectID} = useParams()
    const [task, setTask] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedTables, setIsLoadedTables] = useState(false)
    const [records, setRecords] = useState({
        Tasks: [],
        Comments: [],
        Events: [],
    })
    const [isOpenModalTask, setIsOpenModalTask] = useState(false)
    const [selectedTable, setSelectedTable] = useState("Comments")
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)
    const [confirmWindowText, setConfirmWindowText] = useState("")
    const [confirmWindowAction, setConfirmWindowAction] = useState("")
    const [isOpenEditWindow, setIsOpenEditWindow] = useState(false)

    const fetchRequest = useCallback(() => {
        let url

        if (type === "task") {
            url = "/get_task/"+taskID
        } else {
            url = "/get_project/"+projectID
        }
        
        FetchRequest("GET", url, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setTask(response.data)
                    } else {
                        setTask({})
                    }
                    setIsLoaded(true)
                }
            })
    }, [projectID, taskID, type])

    useEffect(() => {
        setIsLoaded(false)
        setIsLoadedTables(false)
        setRecords({
            Tasks: [],
            Comments: [],
            Events: [],
        })
        setTask({})
        setSelectedTable("Comments")
        fetchRequest()
    }, [taskID, fetchRequest])

    const fetchRequestTable = useCallback(() => {
            let url

            if (type === "task") {
                url = "/get_task_common/"+taskID
            } else {
                url = "/get_project_common/"+projectID
            }
            
            FetchRequest("GET", url, null)
                .then(response => {
                    if (response.success) {
                        if (response.data != null) {
                            let updatedRecords = {...records}
                            if (response.data.SubTasks != null) {
                                updatedRecords.Tasks = response.data.SubTasks
                            } else {
                                updatedRecords.Tasks = []
                            }
                            if (response.data.Events != null) {
                                updatedRecords.Events = response.data.Events
                            } else {
                                updatedRecords.Events = []
                            }
                            if (response.data.Comments != null) {
                                updatedRecords.Comments = response.data.Comments
                            } else {
                                updatedRecords.Comments = []
                            }

                            setRecords(updatedRecords)
                        } else {
                            setRecords({
                                Tasks: [],
                                Comments: [],
                                Events: [],
                            })
                        }

                        setIsLoadedTables(true)
                    }
                })
    }, [taskID, projectID, type])

    useEffect(() => {
        fetchRequestTable()
    }, [task, fetchRequestTable])

    const taskAction = (typeAction) => {
        setIsOpenConfirmWindow(false)
        setConfirmWindowText("")
        setConfirmWindowAction("")
        setIsLoaded(false)
        setIsLoadedTables(false)

        FetchRequest("POST", `/${type}_action/${typeAction}`, task)
            .then(response => {
                if (response.success) {
                    setTask({ ...task, IsClosed: response.data.IsClosed, Executor: response.data.Executor, CurrentUserIsOwner: response.data.CurrentUserIsOwner, Status: response.data.Status, AcceptedAt: response.data.AcceptedAt})
                    fetchRequestTable()
                    setIsLoaded(true)
                }
            })
    }

    const handlerApplyChanges = (record) => {
        let updatedRecord = {
            ...records,
            ...record
        }

        setTask(updatedRecord)
    }

    const handlerAddTask = (task) => {
        let updatedRecords = records
        updatedRecords.Tasks.push(task)
        setRecords(updatedRecords);
    }

    const handlerConfirmAction = (action) => {
        let text
        if (type === "task") {
            switch (action) {
                case "reopen": text = "Вы уверены, что хотите переоткрыть задачу?"; break
                case "close": text = "Вы уверены, что хотите закрыть задачу?"; break
                case "accept": text = "Вы уверены, что хотите принять задачу в обработку?"; break
                case "delete": text = "Вы уверены, что хотите удалить задачу?"; break
                default: text = ""
            }
        } else {
            switch (action) {
                case "reopen": text = "Вы уверены, что хотите переоткрыть проект?"; break
                case "close": text = "Вы уверены, что хотите закрыть проект?"; break
                case "accept": text = "Вы уверены, что хотите принять проект в обработку?"; break
                case "delete": text = "Вы уверены, что хотите удалить проект?"; break
                default: text = ""
            }
        }

        setIsOpenConfirmWindow(true)
        setConfirmWindowText(text)
        setConfirmWindowAction(action)
    }

    return (
        <section className={"tasks-view-page"}>
            <div className="head">
                {type === "task" ?
                    <div className="block">
                        <div className="icon"><FontAwesomeIcon icon={faListCheck} /></div>
                        <div className="text">
                            <div><Link to={"/tasks"}>Задачи</Link> / <Link to={"/tasks/view/"+taskID}>Задача: {taskID}</Link></div>
                            <h1>{task.Name}</h1>
                        </div>
                    </div>
                    :
                    <div className="block">
                        <div className="icon"><FontAwesomeIcon icon={faDiagramProject} /></div>
                        <div className="text">
                            <div><Link to={"/projects"}>Проекты</Link> / <Link to={"/projects/view/"+projectID}>Проект: {projectID}</Link></div>
                            <h1>{task.Name}</h1>
                        </div>
                    </div>}
                <Notification />
            </div>
            {isOpenModalTask && type === "task" && <TaskModalCreate setModalCreate={setIsOpenModalTask} addTask={handlerAddTask} type={"subtask"} mainTaskID={task.ID}/>}
            {isOpenModalTask && type === "project" && <TaskModalCreate setModalCreate={setIsOpenModalTask} addTask={handlerAddTask} type={"subproject"} mainTaskID={task.ID}/>}
            {isOpenEditWindow && <TaskModalCreate setModalCreate={setIsOpenEditWindow} addTask={handlerApplyChanges}  type={"task"} action={"edit"} editRecord={task}/>}
            {isOpenConfirmWindow && <ConfirmWindow text={confirmWindowText} confirmFunction={() => taskAction(confirmWindowAction)} setWindowState={setIsOpenConfirmWindow} /> }
            {isLoaded ?
                <div className="tasks-view view-page">
                    <div className="left-side">

                        <div className="contain-info">
                            <h3>Информация</h3>
                            <div className="info-block">
                                <span className="header">Статус:</span>
                                <span className={"span bg-"+task.Status.Color}>{task.Status.TranslateValue}</span>
                            </div>
                            {"MainTaskID" in task && task.MainTaskID.Valid &&
                            <div className="info-block">
                                <span className="header">Основная задача:</span>
                                <span><Link to={"/tasks/view/"+task.MainTaskID.Int64}>{task.MainTaskName.String}</Link></span>
                            </div> }
                            {"Project" in task && task.Project.ID > 0 &&
                                <div className="info-block">
                                    <span className="header">Основной проект:</span>
                                    <span><Link to={"/projects/view/"+task.Project.ID}>{task.Project.Name}</Link></span>
                                </div> }
                            <div className="info-block">
                                <span className="header">Краткое описание:</span>
                                <span>{task.Name}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Описание:</span>
                                <span>{task.Description.String}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Исполнительный отдел:</span>
                                <span className={"span bg-"+task.ExecutiveDepartment.Color}>{task.ExecutiveDepartment.TranslateValue}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Исполнитель:</span>
                                <span>{task.Executor.ID > 0 ? <Link to={"/users/view/"+task.Executor.ID}>{task.Executor.Name}</Link> : "-"}</span>
                            </div>
                            {task.WorkGroup != null && task.WorkGroup.length > 0 &&
                                <div className="info-block">
                                    <span className="header">Рабочая группа:</span>
                                    <div className={"work-group"}>
                                        {task.WorkGroup.map((user, index) => (
                                            <span key={"work-group-"+index} className={"span bg-"+user.Role.Color} style={{fontSize: "14px", margin: "3px"}}>
                                                <Link style={{color: "#ffffff"}} to={"/users/view/"+user.ID}>{user.Name.split(" ").length === 3 ?
                                                    `${user.Name.split(" ")[0]} ${user.Name.split(" ")[1][0]}. ${user.Name.split(" ")[2][0]}.` : user.Name}</Link>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className="info-block">
                                <span className="header">Приоритет:</span>
                                <span className={"span bg-"+task.Priority.Color}>{task.Priority.TranslateValue}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Крайний срок:</span>
                                <span>{task.PeriodExecution.Valid ? new Date(task.PeriodExecution.Int64).toLocaleString().slice(0,10) : "-"}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Дата принятия:</span>
                                <span>{task.AcceptedAt.Valid ? new Date(task.AcceptedAt.Int64).toLocaleString() : "-"}</span>
                            </div>
                            <div className="info-block">
                                <span className="header">Создал:</span>
                                <span><Link to={"/users/view/"+task.Creator.ID}>{task.Creator.Name}</Link></span>
                            </div>
                            <div className="info-block">
                                <span className="header">Создан:</span>
                                <span>{new Date(task.CreatedAt).toLocaleString()}</span>
                            </div>
                        </div>
                        {type === "task" ?
                            (task.CurrentUserIsOwner || task.IsClosed || task.Executor.ID <= 0 || task.CurrentUserIsCreator) && <div className="contain-status">
                                {((task.Status.Value === "new" || task.Status.Value === "expire") && !task.AcceptedAt.Valid && (task.Executor.ID <= 0 || task.CurrentUserIsOwner)) && <button className="bg-blue" onClick={() => handlerConfirmAction("accept")}>Принять в обработку</button>}
                                {(task.Status.Value === "new" || task.Status.Value === "processing" || task.Status.Value === "expire") && (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director" || task.CurrentUserIsCreator || (task.AcceptedAt.Valid && task.CurrentUserIsOwner && (task.Status.Value === "processing" || task.Status.Value === "expire"))) && <button className="bg-blue" onClick={() => handlerConfirmAction("close")}>Закрыть задачу</button>}
                                {task.IsClosed && task.Status.Value === "close" && <button className="bg-blue" onClick={() => handlerConfirmAction("reopen")}>Переоткрыть задачу</button>}

                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director" || task.CurrentUserIsCreator) && <button className="bg-blue" onClick={() => setIsOpenEditWindow(true)}>Изменить задачу</button>}
                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director") && task.Status.Value !== "delete" && <button className="bg-blue" onClick={() => handlerConfirmAction("delete")}>Удалить задачу</button>}

                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director" || task.CurrentUserIsOwner || task.CurrentUserIsCreator) && !task.IsClosed && "MainTaskID" in task && !task.MainTaskID.Valid && <button className="bg-blue" onClick={() => setIsOpenModalTask(true)}>Создать подзадачу</button>}
                            </div>
                            :
                            (task.CurrentUserIsOwner || task.IsClosed || task.Executor.ID <= 0 || task.CurrentUserIsCreator) && <div className="contain-status">
                                {((task.Status.Value === "new" || task.Status.Value === "expire") && !task.AcceptedAt.Valid && (task.Executor.ID <= 0 || task.CurrentUserIsOwner)) && <button className="bg-blue" onClick={() => handlerConfirmAction("accept")}>Принять в обработку</button>}
                                {(task.Status.Value === "new" || task.Status.Value === "processing" || task.Status.Value === "expire") && (localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director" || task.CurrentUserIsCreator || (task.AcceptedAt.Valid && task.CurrentUserIsOwner && (task.Status.Value === "processing" || task.Status.Value === "expire"))) && <button className="bg-blue" onClick={() => handlerConfirmAction("close")}>Закрыть проект</button>}
                                {task.IsClosed && task.Status.Value === "close" && <button className="bg-blue" onClick={() => handlerConfirmAction("reopen")}>Переоткрыть проект</button>}

                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director" || task.CurrentUserIsCreator) && <button className="bg-blue" onClick={() => setIsOpenEditWindow(true)}>Изменить проект</button>}
                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director") && task.Status.Value !== "delete" && <button className="bg-blue" onClick={() => handlerConfirmAction("delete")}>Удалить проект</button>}

                                {(localStorage.getItem("user-role") === "SuperAdmin" ||  localStorage.getItem("user-role") === "Director" || task.CurrentUserIsOwner || task.CurrentUserIsCreator) && !task.IsClosed && type === "project" && <button className="bg-blue" onClick={() => setIsOpenModalTask(true)}>Создать задачу к проекту</button>}
                            </div>}
                    </div>
                    <div className="right-side">
                        <div className="table-contain">
                            <div className="sub-nav">
                                <span className={selectedTable === "Comments" ? "active" : ""}
                                      onClick={() => {
                                          setIsLoadedTables(false)
                                          setSelectedTable("Comments")
                                          fetchRequestTable()
                                      }}>Чат</span>
                                <span className={selectedTable === "Events" ? "active" : ""}
                                      onClick={() => setSelectedTable("Events")}>События</span>
                                {records.Tasks.length > 0 && <span className={selectedTable === "Tasks" ? "active" : ""}
                                                                      onClick={() => setSelectedTable("Tasks")}>Подзадачи</span>}

                            </div>
                            {selectedTable === "Comments" ? type === "task" ? isLoadedTables && <TaskChat allMessages={records.Comments} recordID={taskID} type={"task"}/> : isLoadedTables && <TaskChat allMessages={records.Comments} recordID={projectID} type={"project"}/>
                                : selectedTable === "Events" ? isLoadedTables && <Table records={records[selectedTable]} type={"concretelyEvents"} additionalClass={"view task"} reload={fetchRequest}/>
                                    : isLoadedTables && <Table records={records[selectedTable]} type={selectedTable.toLowerCase()} additionalClass={"view task"} reload={fetchRequest}/>}
                        </div>
                    </div>
                </div>
                : <Preload />
            }
        </section>
    )
}

export default TaskViewPage