import React, {useCallback, useEffect, useState} from "react";
import CustomSelectType1 from "./CustomSelectType1";
import FetchRequest from "../fetchRequest";
import ConfirmWindow from "./ConfirmWindow";
import SelectTable from "./SelectTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const TaskModalCreate = ({addTask, setModalCreate, type, mainTaskID, editRecord, action}) => {
    const [allUsers, setAllUsers] = useState([])

    const [users, setUsers] = useState([])
    const [selectUser, setSelectUser] = useState({})
    const [isOpenUser, setIsOpenUser] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState({
        Users: [],
        IsSelectAllUsers: false
    })
    const [isOpenSelectTableUsers, setIsOpenSelectTableUsers] = useState(false)

    const [departments, setDepartments] = useState([])
    const [selectDepartment, setSelectDepartment] = useState({})
    const [isOpenDepartments, setIsOpenDepartments] = useState(false)

    const [priorities, setPriorities] = useState([])
    const [selectPriority, setSelectPriority] = useState({})
    const [isOpenPriorities, setIsOpenPriorities] = useState(false)
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)
    const [sendBody, setSendBody] = useState({})

    const [defaultValues, setDefaultValues] = useState({
        Users: "Исполнитель",
        Departments: "Отдел",
        Priorities: "Приоритет",
    })

    const handlerModalCreateClose = (e) => {
        if (e.target.className === "modal-window") {
            setModalCreate(false)
        }
    }

    useEffect(() => {
        if ("ID" in selectDepartment) {
            if (selectDepartment.Value === "allDepartments") {
                setUsers(allUsers)
            } else {
                setUsers(allUsers.filter(user => user.Department.ID === selectDepartment.ID))
            }
        }
    }, [selectDepartment, allUsers])

    useEffect(() => {
       if ("ID" in selectUser) {
           setSelectDepartment(departments.filter(department => department.ID === selectUser.Department.ID)[0])
           setDefaultValues(prevState => ({
               ...prevState,
               Departments: departments.filter(department => department.ID === selectUser.Department.ID)[0].TranslateValue,
           }))
       }
    }, [selectUser, departments])

    const handlerSwitchSelectBlock = (e) => {
        let selects = document.querySelectorAll(".custom-select-type1")
        switch (e.target.closest(".custom-select-type1")) {
            case selects[0]: setIsOpenUser(false); setIsOpenPriorities(false);break;
            case selects[1]: setIsOpenDepartments(false); setIsOpenPriorities(false);break;
            case selects[2]: setIsOpenDepartments(false); setIsOpenUser(false);break;
            default:
                setIsOpenDepartments(false)
                setIsOpenUser(false)
                setIsOpenPriorities(false)
        }
    }

    const fetchRequest = useCallback(() => {
        FetchRequest("GET", "/get_task_modal_data", null)
            .then(response => {
                if (response.success && response.data != null) {
                    if (response.data.Users != null) {
                        setUsers(response.data.Users)
                        setAllUsers(response.data.Users)
                    }
                    if (response.data.Departments != null) {
                        setDepartments(response.data.Departments)
                    }
                    if (response.data.Priorities != null) {
                        setPriorities(response.data.Priorities)
                    }
                    if (action === "edit") {
                        setSelectDepartment(editRecord.ExecutiveDepartment)
                        if (editRecord.Executor.ID > 0) {
                            setSelectUser(response.data.Users.filter(user => user.ID === editRecord.Executor.ID)[0])
                        }
                        setSelectPriority(editRecord.Priority)
                    }
                }
            })
    }, [])

    useEffect(() => {
        fetchRequest()

        document.addEventListener("click", handlerSwitchSelectBlock);
        return () => {
            document.removeEventListener("click", handlerSwitchSelectBlock);
        };
    },[fetchRequest])

    const validateSendData = () => {
        let name,description,executiveDepartment,executor,periodExecution,priority
        let result = true

        if (document.querySelector("#name").value.trim().length > 0) {
            name = document.querySelector("#name").value
            document.querySelector("#name").style.border = "1px solid #e5e5e5"
        } else {
            document.querySelector("#name").style.border = "1px solid #ff0000"
            result = false
        }

        if (document.querySelector("#description").value.trim().length > 0) {
            description = {
                String: document.querySelector("#description").value,
                Valid: true,
            }
        } else {
            description = {
                String: "",
                Valid: false,
            }
        }

        if ("ID" in selectDepartment) {
            document.querySelector("#department").style.border = "1px solid #e5e5e5"
            executiveDepartment = selectDepartment
        } else {
            document.querySelector("#department").style.border = "1px solid #ff0000"
            result = false
        }

        if ("ID" in selectUser) {
            executor = selectUser
        } else {
            executor = null
        }

        if (document.querySelector("#period_execution").value) {
            let currentDate = new Date()
            currentDate.setHours(0, 0, 0, 0)
            if (currentDate.getTime() < new Date(document.querySelector("#period_execution").value).getTime()) {
                periodExecution = {
                    Int64: new Date(document.querySelector("#period_execution").value).getTime(),
                    Valid: true
                }
            } else {
                document.querySelector("#period_execution").style.border = "1px solid #ff0000"
                result = false
            }
        } else {
            periodExecution = {
                Int64: 0,
                Valid: false
            }
        }

        if ("ID" in selectPriority) {
            priority = selectPriority
            document.querySelector("#priorities").style.border = "1px solid #e5e5e5"
        } else {
            document.querySelector("#priorities").style.border = "1px solid #ff0000"
            result = false
        }

        if (result) {
            let sendData
            switch (type) {
                case "task":
                case "project":
                    sendData = {
                        Name: name,
                        Description: description,
                        ExecutiveDepartment: executiveDepartment,
                        Executor: executor,
                        PeriodExecution: periodExecution,
                        Priority: priority,
                        WorkGroup: selectedUsers.Users,
                    }; break
                case "subtask":
                    sendData = {
                        Name: name,
                        Description: description,
                        ExecutiveDepartment: executiveDepartment,
                        Executor: executor,
                        PeriodExecution: periodExecution,
                        Priority: priority,
                        IsSubTask: true,
                        MainTaskID: {
                            Int64: mainTaskID,
                            Valid: true
                        },
                        WorkGroup: selectedUsers.Users,
                    }; break
                case "subproject":
                    sendData = {
                        Name: name,
                        Description: description,
                        ExecutiveDepartment: executiveDepartment,
                        Executor: executor,
                        PeriodExecution: periodExecution,
                        Priority: priority,
                        IsTaskOfProject: true,
                        Project: {ID: mainTaskID},
                        WorkGroup: selectedUsers.Users,
                    }; break
                default: sendData = {}
            }

            return sendData
        } else {
            return false
        }
    }

    const handlerCreateTask = () => {
        let sendTaskData = validateSendData()
        let url

        if (type === "project") {
            url = "/create_project"
        } else {
            url = "/create_task"
        }

        if (sendTaskData) {
            FetchRequest("POST", url, sendTaskData)
                .then(response => {
                    if (response.success) {
                        addTask(response.data)
                        setModalCreate(false)
                    }
                })
        }
    }

    const handlerSaveChange = () => {
        setIsOpenConfirmWindow(false)
        setModalCreate(false)

        let url
        if (type === "project") {
            url = "/edit_project"
        } else {
            url = "/edit_task"
        }

        FetchRequest("POST", url, sendBody)
            .then(response => {
                if (response.success) {
                    addTask(response.data)
                    setModalCreate(false)
                }
            })
    }

    const handlerConfirmSave = () => {
        let sendTaskData= validateSendData()

        if (sendTaskData) {
            sendTaskData = {
                ...editRecord,
                ...sendTaskData,
            }
            setIsOpenConfirmWindow(true)
            setSendBody(sendTaskData)
        }
    }

    const handlerDeleteSelectedUser = (user) => {
        setSelectedUsers(prevState => ({
            Users: prevState.Users.filter(record => record.ID !== user.ID),
            IsSelectAllUsers: false
        }))
    }

    useEffect(() => {
        if (editRecord != null && editRecord.WorkGroup != null) {
            let usersArray = []

            for (let item of editRecord.WorkGroup) {
                usersArray.push(item)
            }

            setSelectedUsers({
                Users: usersArray,
                IsSelectAllUsers: false
            })
        }
    }, [editRecord])

    return (
        <div className={"modal-window"} onMouseDown={handlerModalCreateClose}>
            {isOpenConfirmWindow && <ConfirmWindow setWindowState={setIsOpenConfirmWindow} text={"Вы уверены, что хотите изменить задачу?"} confirmFunction={handlerSaveChange} />}
            {isOpenSelectTableUsers && <SelectTable uri={null} type={"select-users"} setModalOpen={setIsOpenSelectTableUsers} selectedRecords={selectedUsers} setSelectedRecords={setSelectedUsers} allRecords={allUsers} />}
            <div className="form">
                <h2>{type === "project" ? action === "create" ? "Создание проекта"
                    : "Изменение проекта" : action === "create" ? "Создание задачи"
                    : "Изменение задачи"}</h2>
                <div className="fields">
                    <label>
                        <span>Краткое описание*</span>
                        <input type="text" id={"name"} defaultValue={action === "edit" ? editRecord.Name : ""}/>
                    </label>
                    <label>
                        <span>Описание</span>
                        <textarea id="description" cols="30" rows="4" placeholder="Описание" defaultValue={action === "edit" ? editRecord.Description.String : ""}></textarea>
                    </label>
                    <label>
                        <span>Исполнительный отдел*</span>
                        <CustomSelectType1 defaultValue={action === "edit" ? selectDepartment.TranslateValue : defaultValues.Departments}
                                           values={departments}
                                           name={"department"}
                                           isOpen={isOpenDepartments}
                                           setOpen={setIsOpenDepartments}
                                           setSelectedValue={setSelectDepartment}
                                           type={"default"}/>
                    </label>
                    <label>
                        <span>Исполнитель</span>
                        <CustomSelectType1 defaultValue={action === "edit" && "ID" in selectUser ? selectUser.Name : defaultValues.Users}
                                           values={users}
                                           name={"users"}
                                           isOpen={isOpenUser}
                                           setOpen={setIsOpenUser}
                                           setSelectedValue={setSelectUser}
                                           type={"user"}/>
                    </label>
                    <label>
                        <span>Рабочая группа:</span>
                        <div className="selected-contain" id="selected_users">
                            {selectedUsers.IsSelectAllUsers ? <span className="bg-gradient" onClick={() => setSelectedUsers({Users: [], IsSelectAllUsers: false})}>Все пользователи <FontAwesomeIcon icon={faXmark} /></span> :
                                selectedUsers.Users.map((user, index) => (
                                    <span key={"selected-record-"+index} className={"bg-"+user.Role.Color} onClick={() => handlerDeleteSelectedUser(user)}>{user.Name.split(" ").length === 3 ?
                                        `${user.Name.split(" ")[0]} ${user.Name.split(" ")[1][0]}. ${user.Name.split(" ")[2][0]}.` : user.Name} <FontAwesomeIcon icon={faXmark} /></span>
                                ))}
                        </div>
                        <div className="button" onClick={() => setIsOpenSelectTableUsers(true)}>Добавить</div>
                    </label>
                    <label>
                        <span>Приоритет*</span>
                        <CustomSelectType1 defaultValue={action === "edit" ? selectPriority.TranslateValue : defaultValues.Priorities}
                                           values={priorities}
                                           name={"priorities"}
                                           isOpen={isOpenPriorities}
                                           setOpen={setIsOpenPriorities}
                                           setSelectedValue={setSelectPriority}
                                           type={"default"}/>
                    </label>
                    <label>
                        <span>Крайний срок</span>
                        <input type="date" id={"period_execution"} placeholder={"*Обязательно"} defaultValue={action === "edit" && editRecord.PeriodExecution.Valid ?
                            `${new Date(editRecord.PeriodExecution.Int64).getFullYear()}-${String(new Date(editRecord.PeriodExecution.Int64).getMonth() + 1).padStart(2, "0")}-${String(new Date(editRecord.PeriodExecution.Int64).getDate()).padStart(2, "0")}`
                            : ""}/>
                    </label>

                    <div className="buttons">
                        {action === "edit" ? <button className={"create"} onClick={handlerConfirmSave}>Сохранить</button>
                        : <button className={"create"} onClick={handlerCreateTask}>Создать</button>}
                        <button className={"cancel"} onClick={() => setModalCreate(false)}>Отмена</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TaskModalCreate