import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Table from "./Table";
import Preload from "./Preload";
import Notification from "./Notification";
import FetchRequest from "../fetchRequest";

const EventsPage = ({type, uri}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [records, setRecords] = useState([])

    useEffect(() => {
        setIsLoaded(false)
    }, [type])

    useEffect(() => {
        FetchRequest("GET", uri, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setRecords(response.data.sort(function (event1, event2) {
                            return event2.Date - event1.Date
                        }))
                    } else {
                        setRecords([])
                    }
                    setIsLoaded(true)
                }
            })
    }, [type, uri])

    let tableType
    if (type === "allEvents") {
        tableType = "events"
    } else {
        tableType = "concretelyEvents"
    }

    return (
        <section className={"events-page"}>
            <div className="head">
                <div className="block">
                    <div className="icon"><FontAwesomeIcon icon={faBook} /></div>
                    <div className="text">
                        <div><Link to={"/events"}>Все события</Link> / {type === "projectEvents" && <Link to={"/events/projects"}>События проектов</Link>}
                            {type === "taskEvents" && <Link to={"/events/tasks"}>События задач</Link>}
                            {type === "userEvents" && <Link to={"/events/users"}>События пользователей</Link>}
                        </div>
                        {type === "allEvents" && <h1>Все события</h1>}
                        {type === "projectEvents" && <h1>События проектов</h1>}
                        {type === "taskEvents" && <h1>События задач</h1>}
                        {type === "userEvents" && <h1>События пользователей</h1>}
                    </div>
                </div>
                <Notification />
            </div>
            <div className="table-contain">
                <div className="sub-nav">
                    <Link to={"/events"}>
                        <span className={type === "allEvents" ? "active" : ""}>Все</span>
                    </Link>
                    <Link to={"/events/tasks"}>
                        <span className={type === "taskEvents" ? "active" : ""}>Задачи</span>
                    </Link>
                    <Link to={"/events/projects"}>
                        <span className={type === "projectEvents" ? "active" : ""}>Проекты</span>
                    </Link>
                    <Link to={"/events/users"}>
                        <span className={type === "userEvents" ? "active" : ""}>Пользователи</span>
                    </Link>
                </div>
                {isLoaded ?
                    type === "allEvents" ? <Table key={"table-"+type} records={records} type={tableType} additionalClass={"all-events"}/>
                        : <Table key={"table-"+type} records={records} type={tableType}/>
                    : <Preload />}
            </div>
        </section>
    )
}

export default EventsPage