import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import Aside from "./Aside";


const PrivateRoute = () => {
    const token = localStorage.getItem("token")

    if (token) {
        return (
            <div>
                <Aside />
                <div className={"app"}>
                    <Outlet/>
                </div>
            </div>
        )
    } else {
        return <Navigate to="/login" />;
    }
};

export default PrivateRoute