import React, {useEffect} from "react";

const HomePage = () => {
    useEffect(() => {
        window.location.href = "/tasks"
    }, [])

    return (
        <div></div>
    )
}

export default HomePage