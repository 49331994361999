import React, {useEffect, useRef, useState} from "react";
import CustomSelectType1 from "./CustomSelectType1";
import API_DOMAIN from "../config";
import ConfirmWindow from "./ConfirmWindow";
import FetchRequest from "../fetchRequest";

const UsersPageModalCreate = ({addUser, setModalCreate, action, editUser}) => {
    const isFirstRender = useRef(true)
    const [roles, setRoles] = useState([])
    const [departments, setDepartments] = useState([])
    const [selectRole, setSelectRole] = useState({})
    const [genders, setGenders] = useState([])
    const [selectGender, setSelectGender] = useState({})
    const [selectDepartment, setSelectDepartment] = useState({})
    const [isOpenRole, setIsOpenRole] = useState(false)
    const [isOpenGender, setIsOpenGender] = useState(false)
    const [isOpenDepartment, setIsOpenDepartment] = useState(false)
    const [confirmWindowCreate, setConfirmWindowCreate] = useState(false)

    const handlerModalCreateClose = (e) => {
        if (e.target.className === "modal-window") {
            setModalCreate(false)
        }
    }

    useEffect(() => {
        if (action === "edit") {
            setSelectRole(editUser.Role)
            setSelectDepartment(editUser.Department)
            setSelectGender(editUser.Gender)
        }
    }, [action, editUser])

    const handlerSwitchSelectBlock = (e) => {
        let selects = document.querySelectorAll(".custom-select-type1")
        switch (e.target.closest(".custom-select-type1")) {
            case selects[0]: setIsOpenGender(false); setIsOpenDepartment(false); break;
            case selects[1]: setIsOpenGender(false); setIsOpenRole(false); break;
            case selects[2]: setIsOpenRole(false); setIsOpenDepartment(false); break;
            default:
                setIsOpenRole(false)
                setIsOpenGender(false)
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            let options = {
                method: "GET",
                headers: { "Authorization": `Bearer ${localStorage.getItem("token")}`}
            }

            FetchRequest("GET", "/get_departments", null)
                .then(response => {
                    if (response.success) {
                        setDepartments(response.data)
                    }
                })

            FetchRequest("GET", "/get_genders", null)
                .then(response => {
                    if (response.success) {
                        setGenders(response.data)
                    }
                })

            FetchRequest("GET", "/get_roles", null)
                .then(response => {
                    if (response.success) {
                        setRoles(response.data)
                    }
                })

            isFirstRender.current = false
        }

        document.addEventListener("click", handlerSwitchSelectBlock);
        return () => {
            document.removeEventListener("click", handlerSwitchSelectBlock);
        };
    },[])

    const checkUniqueData = async (login, email, phone) => {
        try {
            const options = {
                method: "POST",
                headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
                body: JSON.stringify({
                    Login: login,
                    Email: email,
                    Phone: phone
                })
            };

            const response = await fetch(`${API_DOMAIN.HTTP}/check_unique_data`, options);

            if (response.status === 401) {
                localStorage.removeItem("token");
                window.location.href = "/login";
            }

            if (response.status === 403) {
                window.location.href = "/";
            }

            const data = await response.json();

            if (data != null) {
                return data;
            } else {
                return { Error: true };
            }
        } catch (error) {
            console.error(error);
            return { Error: true };
        }
    };

    const checkUniqueConcretelyData = async (checkField, fieldValue) => {
        try {
            const options = {
                method: "POST",
                headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
                body: JSON.stringify(fieldValue)
            };

            const response = await fetch(`${API_DOMAIN.HTTP}/check_unique_concretely_data/${checkField}`, options);

            if (response.status === 401) {
                localStorage.removeItem("token");
                window.location.href = "/login";
            }

            if (response.status === 403) {
                window.location.href = "/";
            }

            const data = await response.json();

            if (data != null) {
                return data;
            } else {
                return { Error: true };
            }
        } catch (error) {
            console.error(error);
            return { Error: true };
        }
    }

    const validateSendData = async () => {
        let name,login,password,phone,email, department, role, gender, telegram, canCreateNotification
        let result = true

        canCreateNotification = document.querySelector("#can_create_notification").checked

        if (document.querySelector("#name").value.trim().length > 0) {
            name = document.querySelector("#name").value
            document.querySelector("#name").style.border = "1px solid #e5e5e5"
        } else {
            document.querySelector("#name").style.border = "1px solid #ff0000"
            result = false
        }

        if (document.querySelector("#login").value.trim().length > 0) {
            login = document.querySelector("#login").value
            document.querySelector("#login").style.border = "1px solid #e5e5e5"
        } else {
            document.querySelector("#login").style.border = "1px solid #ff0000"
            result = false
        }

        if (action === "create") {
            if (document.querySelector("#password").value.trim().length > 0 &&
                document.querySelector("#password").value.trim() === document.querySelector("#password_confirm").value.trim()) {
                password = document.querySelector("#password").value
                document.querySelector("#password").style.border = "1px solid #e5e5e5"
            } else {
                document.querySelector("#password").style.border = "1px solid #ff0000"
                result = false
            }
        }
        if (action === "edit") {
            if (document.querySelector("#password").value.trim().length > 0) {
                if (document.querySelector("#password").value.trim() === document.querySelector("#password_confirm").value.trim()) {
                    password = document.querySelector("#password").value
                    document.querySelector("#password").style.border = "1px solid #e5e5e5"
                } else {
                    document.querySelector("#password").style.border = "1px solid #ff0000"
                    result = false
                }
            } else {
                password = ""
            }
        }

        if (document.querySelector("#phone").value.trim().length > 0) {
            phone = {
                String: document.querySelector("#phone").value,
                Valid: true,
            }
        } else {
            phone = {
                String: "",
                Valid: false,
            }
        }

        if (document.querySelector("#email").value.trim().length > 0) {
            email = {
                String: document.querySelector("#email").value,
                Valid: true,
            }
        } else {
            email = {
                String: "",
                Valid: false,
            }
        }

        if (document.querySelector("#telegram").value.trim().length > 0) {
            telegram = {
                String: document.querySelector("#telegram").value,
                Valid: true,
            }
        } else {
            telegram = {
                String: "",
                Valid: false,
            }
        }

        if ("ID" in selectDepartment) {
            document.querySelector("#department").style.border = "1px solid #e5e5e5"
            department = selectDepartment
        } else {
            document.querySelector("#department").style.border = "1px solid #ff0000"
            result = false
        }

        if ("ID" in selectRole) {
            document.querySelector("#role").style.border = "1px solid #e5e5e5"
            role = selectRole
        } else {
            document.querySelector("#role").style.border = "1px solid #ff0000"
            result = false
        }

        if ("ID" in selectGender) {
            gender = selectGender
            document.querySelector("#gender").style.border = "1px solid #e5e5e5"
        } else {
            document.querySelector("#gender").style.border = "1px solid #ff0000"
            result = false
        }

        if (action === "create") {
            let checkResult = await checkUniqueData(login, email, phone)

            if (!checkResult.Error) {
                if (!checkResult.Result) {
                    if (!checkResult.Login) {
                        document.querySelector("#login").style.border = "1px solid #ff0000"
                        result = false
                    } else { document.querySelector("#login").style.border = "1px solid #e5e5e5";}

                    if (!checkResult.Email) {
                        document.querySelector("#email").style.border = "1px solid #ff0000"
                        result = false
                    } else { document.querySelector("#email").style.border = "1px solid #e5e5e5";}

                    if (!checkResult.Phone) {
                        document.querySelector("#phone").style.border = "1px solid #ff0000"
                        result = false
                    } else { document.querySelector("#phone").style.border = "1px solid #e5e5e5";}
                }
            }
        } else {
            if (editUser.Login !== login) {
                let checkResult = await checkUniqueConcretelyData("login", login)

                if (!checkResult.Error) {
                    if (!checkResult.Result) {
                        document.querySelector("#login").style.border = "1px solid #ff0000"
                        result = false
                    } else {
                        document.querySelector("#login").style.border = "1px solid #e5e5e5";
                    }
                }
            }
            if (editUser.Email.String !== email.String) {
                let checkResult = await checkUniqueConcretelyData("email", email.String)

                if (!checkResult.Error) {
                    if (!checkResult.Result) {
                        document.querySelector("#email").style.border = "1px solid #ff0000"
                        result = false
                    } else {
                        document.querySelector("#email").style.border = "1px solid #e5e5e5";
                    }
                }
            }
            if (editUser.Phone.String !== phone.String) {
                let checkResult = await checkUniqueConcretelyData("phone", phone.String)

                if (!checkResult.Error) {
                    if (!checkResult.Result) {
                        document.querySelector("#phone").style.border = "1px solid #ff0000"
                        result = false
                    } else {
                        document.querySelector("#phone").style.border = "1px solid #e5e5e5";
                    }
                }
            }
            if (editUser.TelegramUsername.String !== telegram.String) {
                let checkResult = await checkUniqueConcretelyData("telegram", telegram.String)

                if (!checkResult.Error) {
                    if (!checkResult.Result) {
                        document.querySelector("#telegram").style.border = "1px solid #ff0000"
                        result = false
                    } else {
                        document.querySelector("#telegram").style.border = "1px solid #e5e5e5";
                    }
                }
            }
        }

        if (result) {
            return {
                Name: name,
                Login: login,
                Password: password,
                Phone: phone,
                Email: email,
                Department: department,
                Role: role,
                Gender: gender,
                TelegramUsername: telegram,
                CanCreateNotification: canCreateNotification,
            }
        } else {
            return false
        }
    }

    const handlerCreateUser = async () => {
        let sendUserData = await validateSendData()
        let body, url

        if (action === "create") {
            body = sendUserData
            url = "/create_user"
        } else {
            sendUserData.ID = editUser.ID
            sendUserData.Baned = editUser.Baned
            sendUserData.CreatedAt = editUser.CreatedAt
            sendUserData.UpdatedAt = editUser.UpdatedAt
            body = [editUser, sendUserData]
            url = "/edit_user"
        }

        FetchRequest("POST", url, body)
            .then(response => {
                if (response.success && response.data != null) {
                    addUser(response.data)
                    setConfirmWindowCreate(false)
                    setModalCreate(false)
                }
            })
    }

    const handlerConfirmWindow = async () => {
        let sendUserData = await validateSendData()
        let userDataIsChanged = false

        if (sendUserData) {
            if (action === "edit") {
                sendUserData.ID = editUser.ID
                sendUserData.Baned = editUser.Baned
                sendUserData.CreatedAt = editUser.CreatedAt
                sendUserData.UpdatedAt = editUser.UpdatedAt
                for (let key in editUser) {
                    if (editUser[key] !== sendUserData[key]) {
                        switch (key) {
                            case "Phone":
                            case "Email":
                            case "TelegramUsername":
                                if (editUser[key].String !== sendUserData[key].String) {
                                    userDataIsChanged = true
                                }
                                break
                            case "Role":
                            case "Department":
                            case "Gender":
                                if (editUser[key].ID !== sendUserData[key].ID) {
                                    userDataIsChanged = true
                                }
                                break
                            case "TelegramChatID":
                            case "FlagNewTaskNotification":
                            case "FlagCloseTaskNotification":
                            case "FlagExpiringTaskNotification":
                            case "FlagCompleteTaskNotification":
                            case "AvatarPath":
                            case "AvatarData":
                                break
                            default: userDataIsChanged = true
                        }
                    }
                }
            } else {
                userDataIsChanged = true
            }

            if (userDataIsChanged) {
                setConfirmWindowCreate(true)
            }
        }
    }

    return (
        <div className={"modal-window"} onMouseDown={handlerModalCreateClose}>
            {confirmWindowCreate && <ConfirmWindow text={action === "create" ? "Вы уверены, что хотите создать пользователя?"
            : "Вы уверены, что хотите изменить пользователя?"}
                                                   setWindowState={setConfirmWindowCreate}
                                                   confirmFunction={handlerCreateUser}/>}
            <div className="form">
                <h2>{action === "create" ? "Создание пользователя" : "Изменение пользователя"}</h2>
                <div className="fields">
                    <label>
                        <span>ФИО</span>
                        <input type="text" id={"name"} defaultValue={action === "edit" ? editUser.Name : ""}/>
                    </label>
                    <label>
                        <span>Логин</span>
                        <input type="text" id={"login"} defaultValue={action === "edit" ? editUser.Login : ""}/>
                    </label>
                    <label>
                        <span>Пароль</span>
                        <input type="password" id={"password"}/>
                    </label>
                    <label>
                        <span>Пароль ещё раз</span>
                        <input type="password" id={"password_confirm"}/>
                    </label>
                    <label>
                        <span>Телефон</span>
                        <input type="text" id={"phone"} defaultValue={action === "edit" ? editUser.Phone.String : ""}/>
                    </label>
                    <label>
                        <span>E-mail</span>
                        <input type="text" id={"email"} defaultValue={action === "edit" ? editUser.Email.String : ""}/>
                    </label>
                    <label>
                        <span>Telegram</span>
                        <input type="text" id={"telegram"} defaultValue={action === "edit" ? editUser.TelegramUsername.String : ""}/>
                    </label>

                    <label>
                        <span>Роль</span>
                        <CustomSelectType1 defaultValue={action === "edit" ? selectRole.TranslateValue : "Роль"}
                                           values={roles}
                                           name={"role"}
                                           isOpen={isOpenRole}
                                           setOpen={setIsOpenRole}
                                           setSelectedValue={setSelectRole}
                                           type={"default"}/>
                    </label>

                    <label>
                        <span>Отдел</span>
                        <CustomSelectType1 defaultValue={action === "edit" ? selectDepartment.TranslateValue : "Отдел"}
                                           values={departments}
                                           name={"department"}
                                           isOpen={isOpenDepartment}
                                           setOpen={setIsOpenDepartment}
                                           setSelectedValue={setSelectDepartment}
                                           type={"default"}/>
                    </label>

                    <label>
                        <span>Пол</span>
                        <CustomSelectType1 defaultValue={action === "edit" ? selectGender.TranslateValue : "Пол"}
                                           values={genders}
                                           name={"gender"}
                                           isOpen={isOpenGender}
                                           setOpen={setIsOpenGender}
                                           setSelectedValue={setSelectGender}
                                           type={"default"}/>
                    </label>
                    <label className="checkbox">
                        <input type="checkbox" id="can_create_notification" defaultChecked={action === "edit" ? editUser.CanCreateNotification : false}/>
                        <span>Может создавать уведомления</span>
                    </label>

                    <div className="buttons">
                        {action === "create" ? <button className={"create"} onClick={handlerConfirmWindow}>Создать</button>
                        : <button className={"create"} onClick={handlerConfirmWindow}>Сохранить</button>}
                        <button className={"cancel"} onClick={() => setModalCreate(false)}>Отмена</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UsersPageModalCreate