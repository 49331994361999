import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    faFile, faFileArrowDown, faFileExcel,
    faFileLines, faFilePdf,
    faFileWord, faFileZipper,
    faImage,
    faLocationArrow,
    faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import API_DOMAIN from "../config"
import * as mime from 'react-native-mime-types';

const TaskChat = ({allMessages, recordID, type}) => {

    const [oldMessages, setOldMessages] = useState([])
    const [newMessages, setNewMessages] = useState([])
    const [showNewMessages, setShowNewMessages] = useState([])
    const [socket, setSocket] = useState(null);
    const [inputValue, setInputValue] = useState("")
    const [lastMonthMessage, setLastMonthMessage] = useState(-1)
    const [lastDateMessage, setLastDateMessage] = useState(-1)
    const containerRef = useRef(null);
    const messagesRef = useRef(true);
    const [isShowInputTypes, setIsShowInputTypes] = useState(false)
    const [previewImage, setPreviewImage] = useState(null)
    const [previewDoc, setPreviewDoc] = useState({
        Doc: null,
        Name: "",
        Icon: null,
    })
    const [uploadFile, setUploadFile] = useState(null)
    const [isOpenImage, setIsOpenImage] = useState(false)
    const [openImageSrc, setOpenImageSrc] = useState("")
    const connectedWebSocket = useRef(true)

    useEffect(() => {
        if (localStorage.getItem("my-id")) {
            let url

            if (type === "task") {
                url = `${API_DOMAIN.WS}/ws-task/${recordID}/${localStorage.getItem("token")}`
            } else {
                url = `${API_DOMAIN.WS}/ws-project/${recordID}/${localStorage.getItem("token")}`
            }

            const newSocket = new WebSocket(url);

            newSocket.onopen = () => {
                console.log('WebSocket соединение установлено.');
                setSocket(newSocket);
            };

            newSocket.onmessage = (event) => {
                let message = JSON.parse(event.data);

                setNewMessages((prevMessages) => {
                    return [...prevMessages, message];
                });
            };

            newSocket.onclose = (event) => {
                if (event.wasClean) {
                    console.log(`WebSocket соединение закрыто чисто, код: ${event.code}, причина: ${event.reason}`);
                } else {
                    console.error(`WebSocket соединение закрыто с ошибкой: код ${event.code}`);
                }
            };

            newSocket.onerror = (error) => {
                console.error('WebSocket произошла ошибка:', error);
            };

            return () => {
                setSocket(null)
                newSocket.close();
            };
        } else {
            localStorage.removeItem("token")
            window.location.href = "/login"
        }
    }, [recordID, type]);

    const sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN && (message.trim().length > 0 || uploadFile != null)) {
            let data = {
                OwnerID: localStorage.getItem("my-id"),
                Text: message,
            }

            type === "task" ? data = {...data, TaskID: Number(recordID)} : data = {...data, ProjectID: Number(recordID)}

            if (uploadFile != null) {
                const reader = new FileReader();
                reader.onload = () => {
                    data = {
                        ...data,
                        FileName: uploadFile.name,
                        FileType: uploadFile.type,
                        FileData: reader.result.split(",")[1]
                    }

                    socket.send(JSON.stringify(data));
                };
                reader.readAsDataURL(uploadFile);
            } else {
                socket.send(JSON.stringify(data));
            }

            handlerClearPreview()
        } else {
            console.error('WebSocket не готов для отправки сообщения.');
        }
    };

    const monthNames = useMemo(() => [
        "января", "февраля", "марта", "апреля", "мая",
        "июня", "июля", "августа", "сентября", "октября",
        "ноября", "декабря"
    ], [])

    const handlerDownloadFile = (fileData, fileType, fileName) => {
        const decodedData = atob(fileData);

        const byteCharacters = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
            byteCharacters[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([byteCharacters], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = fileName;
        a.click();

        URL.revokeObjectURL(url);
    }

    const handlerOpenImage = (url) => {
        setIsOpenImage(true)
        setOpenImageSrc(url)
    }

    const buildMessageList = useCallback((messages) => {
        let updatedMessages = []
        let lastMonth = lastMonthMessage
        let lastDate = lastDateMessage

        for (let message of messages) {
            let messageDate = new Date(message.Date)
            let userNameParts = message.User.Name.split(" ")

            if (userNameParts.length === 3) {
                message.User.Name = `${userNameParts[0]} ${userNameParts[1].slice(0,1)}.${userNameParts[2].slice(0,1)}.`
            }

            if (lastMonth !== messageDate.getMonth() || lastDate !== messageDate.getDate()) {

                lastMonth = messageDate.getMonth()
                lastDate = messageDate.getDate()

                updatedMessages.push((<div className="date" key={message.Date}>{lastDate} {monthNames[lastMonth]}</div>))

            }

            if ("FileData" in message && message.FilePath.Valid) {
                let file
                const fileMimeType = mime.lookup(message.FileName.String)
                const allowedMimeTypes = ["image/png", "image/jpeg",];

                if (allowedMimeTypes.includes(fileMimeType)) {
                    const decodedData = atob(message.FileData);

                    const byteCharacters = new Uint8Array(decodedData.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteCharacters[i] = decodedData.charCodeAt(i);
                    }

                    file = (<div className="file" onClick={() => handlerOpenImage(URL.createObjectURL(new Blob([byteCharacters], { type: fileMimeType })))}>
                        <img src={URL.createObjectURL(new Blob([byteCharacters], { type: fileMimeType }))} alt=""/>
                    </div>)
                } else {
                    let icon

                    switch (fileMimeType) {
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            icon = <FontAwesomeIcon icon={faFileWord} />; break
                        case "application/pdf": icon = <FontAwesomeIcon icon={faFilePdf} />; break
                        case "text/plain": icon = <FontAwesomeIcon icon={faFileLines} />; break
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            icon = <FontAwesomeIcon icon={faFileExcel} />; break
                        case "application/x-rar-compressed":
                        case "application/zip":
                        case "application/x-7z-compressed":
                            icon = <FontAwesomeIcon icon={faFileZipper} />; break
                        default:
                            icon = <FontAwesomeIcon icon={faFile} />
                    }

                    file = (
                        <div className="file" onClick={() => handlerDownloadFile(message.FileData, fileMimeType, message.FileName.String)}>
                            <div>
                                <span className="icon">{icon}</span>
                                <span className="file-name">{message.FileName.String}</span>
                                <span className="icon button"><FontAwesomeIcon icon={faFileArrowDown} /></span>
                            </div>
                        </div>
                    )
                }

                updatedMessages.push((
                    <div className={message.CurrentUserIsOwner || message.OwnerID === localStorage.getItem("my-id") ? "message own" : "message"} key={message.ID}>
                        <div className="text">
                            <span className="owner">{message.User.Name}</span>{message.Text.String}<span className="time">{messageDate.toLocaleString().slice(11,17)}</span>
                            {file}
                        </div>
                    </div>))
            } else {
                updatedMessages.push((
                    <div className={message.CurrentUserIsOwner || message.OwnerID === localStorage.getItem("my-id") ? "message own" : "message"} key={message.ID}>
                        <div className="text">
                            <span className="owner">{message.User.Name}</span>{message.Text.String}<span className="time">{messageDate.toLocaleString().slice(11,17)}</span>
                        </div>
                    </div>))
            }
        }

        setLastMonthMessage(lastMonth)
        setLastDateMessage(lastDate)
        if (allMessages.length === 0) {
            setLastMonthMessage(-1)
            setLastDateMessage(-1)
        }

        return updatedMessages
    }, [allMessages.length, lastDateMessage, lastMonthMessage, monthNames])

    useEffect(() => {
        if (newMessages.length > 0) {
            setShowNewMessages(buildMessageList(newMessages))
        }
    }, [newMessages, buildMessageList])

    useEffect(() => {
        if (messagesRef.current) {
            setOldMessages(buildMessageList(allMessages))
            messagesRef.current = false
        }
    }, [allMessages, buildMessageList])

    useEffect(() => {
        setTimeout(() => {
            containerRef.current.scrollTop = containerRef.current.scrollHeight
        }, 10)
    }, [oldMessages, showNewMessages])

    const handlerFileImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const allowedMimeTypes = ["image/png", "image/jpeg",];
            if (allowedMimeTypes.includes(file.type)) {
                // Файл соответствует разрешенным MIME-типам, отправляем на сервер
                if (file.size <= 4194304) {
                    setPreviewDoc({
                        Doc: null,
                        Name: "",
                        Icon: null,
                    })
                    setIsShowInputTypes(false)

                    containerRef.current.style.height = "100%"
                    containerRef.current.style.height = containerRef.current.clientHeight - 121+"px"
                    containerRef.current.scrollTop = containerRef.current.scrollHeight;

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        setPreviewImage(e.target.result)
                        setUploadFile(file)
                    }

                    reader.readAsDataURL(file)
                } else {
                    alert("Размер файла слишком большой.");
                }
            } else {
                alert("Недопустимый формат файла. Пожалуйста, выберите файл другого формата.");
            }
        }
    }

    const handlerFileDocChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileMimeType = mime.lookup(file.name)
            const allowedMimeTypes = [
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/pdf",
                "text/plain",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/x-rar-compressed",
                "application/zip",
                "application/x-7z-compressed"
            ];
            if (allowedMimeTypes.includes(fileMimeType)) {
               if (file.size <= 4194304) {
                   setPreviewImage(null)
                   setIsShowInputTypes(false)

                   containerRef.current.style.height = "100%"
                   containerRef.current.style.height = containerRef.current.clientHeight - 70+"px"
                   containerRef.current.scrollTop = containerRef.current.scrollHeight;

                   const reader = new FileReader();

                   reader.onload = (e) => {
                       let icon

                       switch (fileMimeType) {
                           case "application/msword":
                           case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                               icon = <FontAwesomeIcon icon={faFileWord} />; break
                           case "application/pdf": icon = <FontAwesomeIcon icon={faFilePdf} />; break
                           case "text/plain": icon = <FontAwesomeIcon icon={faFileLines} />; break
                           case "application/vnd.ms-excel":
                           case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                               icon = <FontAwesomeIcon icon={faFileExcel} />; break
                           case "application/x-rar-compressed":
                           case "application/zip":
                           case "application/x-7z-compressed":
                               icon = <FontAwesomeIcon icon={faFileZipper} />; break
                           default:
                               icon = <FontAwesomeIcon icon={faFile} />
                       }

                       setPreviewDoc({Doc: e.target.result, Name: file.name, Icon: icon})
                       setUploadFile(file)
                   }

                   reader.readAsDataURL(file)
               } else {
                   alert("Размер файла слишком большой.");
               }
            } else {
                alert("Недопустимый формат файла. Пожалуйста, выберите файл другого формата.");
            }
        }
    }

    const handlerClearPreview = () => {
        containerRef.current.style.height = "100%"

        setPreviewImage(null)
        setPreviewDoc({
            Doc: null,
            Name: "",
            Icon: null,
        })
        setUploadFile(null)
    }

    const handlerCloseOpenImage = (e) => {
        if (e.target.className === "modal-window image") {
            setIsOpenImage(false)
            setOpenImageSrc("")
        }
    }

    return (
        <div className="chat">
            <div className="messages" ref={containerRef}>
                {oldMessages}
                {showNewMessages}
            </div>
            <div className="input-bar">
                {isOpenImage &&
                    <div className="modal-window image" onClick={handlerCloseOpenImage}>
                        <div className="contain">
                            <img src={openImageSrc} alt=""/>
                        </div>
                    </div>
                }

                {previewImage != null &&
                    <div className="previewImage">
                        <div>
                           <img src={previewImage} alt="" height={"100px"}/>
                           <span onClick={handlerClearPreview}>+</span>
                        </div>
                    </div>
                }

                {previewDoc.Doc != null &&
                    <div className="previewDoc">
                        <div className="document">
                            <span className="icon">{previewDoc.Icon}</span>
                            <span>{previewDoc.Name}</span>
                        </div>
                        <div onClick={handlerClearPreview} className="clear-button">+</div>
                    </div>
                }

                <div className="inputs">
                    <div className="file-input">
                        <div className="button" onClick={() => {setIsShowInputTypes(!isShowInputTypes)}}><FontAwesomeIcon icon={faPaperclip} /></div>
                        {isShowInputTypes && <div className="input-types">
                            <label>
                                <input type="file" accept=".png, .jpg, .jpeg" max="4194304" onChange={handlerFileImageChange}/>
                                <div><FontAwesomeIcon icon={faImage} /> <span>Изображение</span></div>
                            </label>
                            <label>
                                <input type="file" accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .rar, .zip, .7z" max="4194304" onChange={handlerFileDocChange}/>
                                <div><FontAwesomeIcon icon={faFile} /> <span>Файл</span></div>
                            </label>
                        </div>}
                    </div>

                    <input type="text" placeholder="Написать сообщение..." value={inputValue} onInput={(e) => setInputValue(e.target.value)}
                           onKeyDown={(e) => {
                               if (e.key === "Enter") {
                                   sendMessage(inputValue)
                                   setInputValue("")
                               }
                           }}/>
                </div>
                <button title="Отправить" onClick={() => {
                    sendMessage(inputValue)
                    setInputValue("")
                }}><FontAwesomeIcon icon={faLocationArrow} /></button>
            </div>
        </div>
    )
}

export default TaskChat