import React from "react";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CustomSelectType1 = ({defaultValue, values, name, isOpen, setOpen}) => {
    const handlerSmartOpenSelect = (e) => {
        setOpen(!isOpen)
    }

    return(
        <div id={name} className={isOpen ? "custom-select-type2 active" : "custom-select-type2" } onClick={(e) => handlerSmartOpenSelect(e)}>
            <div className="select-value">
                <FontAwesomeIcon icon={faChevronDown} />
                <span>{defaultValue}</span>
            </div>
            <div className={"values-list "+ name}>
                {values}
            </div>
        </div>
    )
}

export default CustomSelectType1