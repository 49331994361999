import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import FetchRequest from "../fetchRequest";
import API_DOMAIN from "../config";
import NotificationModalCreate from "./NotificationModalCreate";
import NotificationControl from "./NotificationControl";
import NotificationTasksProjects from "./NotificationTasksProjects";

const Notification = () => {
    const [isShowNotificationList, setIsShowNotificationList] = useState(false)
    const [oldNotifications, setOldNotifications] = useState([])
    const [newNotifications, setNewNotifications] = useState([])
    const [countUnchecked, setCountUnchecked] = useState(0)
    const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false)
    const [isOpenNotificationModalControl, setIsOpenNotificationModalControl] = useState(false)
    const [isOpenConnectedTasksProjects, setIsOpenConnectedTasksProjects] = useState(false)
    const [customNotificationTasksProjects, setCustomNotificationTasksProjects] = useState({
        Tasks: [],
        Projects: [],
    })
    const [canCreateNotification, setCanCreateNotification] = useState(false)

    const handlerSwitchIsShow = () => {
        setIsShowNotificationList(!isShowNotificationList)
    }

    useEffect(() => {
        setCountUnchecked(oldNotifications.filter(notification => !notification.IsChecked).length + newNotifications.filter(notification => {
            if ("Status" in notification) {
                return !notification.ConnectedUser[0].IsChecked
            }

            return !notification.IsChecked
        }).length)
    }, [oldNotifications, newNotifications])

    useEffect(() => {
        FetchRequest("GET", "/get_notifications", null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        let combinedArray = []
                        if (response.data.Notifications != null && response.data.Notifications.length > 0) {
                            combinedArray.push(...response.data.Notifications)
                        }
                        if (response.data.CustomNotifications != null && response.data.CustomNotifications.length > 0) {
                            combinedArray.push(...response.data.CustomNotifications)
                        }
                        setOldNotifications(combinedArray.sort((a,b) => {
                            const dateA = a.Date || a.CustomNotification.LastSend.Int64;
                            const dateB = b.Date || b.CustomNotification.LastSend.Int64;
                            return dateB - dateA;
                        }))
                        setCanCreateNotification(response.data.Permission)
                    }
                }
            })

        if (localStorage.getItem("my-id")) {
            const newSocket = new WebSocket(`${API_DOMAIN.WS}/ws-notification/${localStorage.getItem("token")}`);

            newSocket.onopen = () => {
                console.log('WebSocket-notification соединение установлено.');
            };

            newSocket.onmessage = (event) => {
                let notification = JSON.parse(event.data);

                if ("Status" in notification) {
                    setNewNotifications(prevState => {
                        let updatedNewNotifications = [...prevState]

                        let indexUpdatedNewNotification = updatedNewNotifications.findIndex(not => "Status" in not && not.ID === notification.ID)

                        if (indexUpdatedNewNotification > -1) {
                            updatedNewNotifications[indexUpdatedNewNotification] = notification
                        } else {
                            updatedNewNotifications = [notification, ...updatedNewNotifications]
                        }

                        return updatedNewNotifications
                    })

                    setOldNotifications(prevNotifications => prevNotifications.filter(not => {
                        if ("CustomNotification" in not) {
                            if (not.CustomNotification.ID === notification.ID) {
                                return false
                            }
                        }

                        return true
                    }))
                } else {
                    setNewNotifications((prevNotifications) => {
                        return [notification, ...prevNotifications];
                    });
                }
            };

            newSocket.onclose = (event) => {
                if (event.wasClean) {
                    console.log(`WebSocket соединение закрыто чисто, код: ${event.code}, причина: ${event.reason}`);
                } else {
                    console.error(`WebSocket соединение закрыто с ошибкой: код ${event.code}`);
                }
            };

            newSocket.onerror = (error) => {
                console.error('WebSocket произошла ошибка:', error);
            };

            return () => {
                newSocket.close();
            };
        } else {
            localStorage.removeItem("token")
            window.location.href = "/login"
        }
    }, [])

    const handlerCheckNotification = (notification, typeNotification) => {
       handlerSwitchIsShow()
       if (!notification.IsChecked){
           FetchRequest("POST", "/check_notification", notification)
               .then(response => {
                   if (response.success) {
                       if (typeNotification === "new") {
                           let indexNotification = newNotifications.findIndex(not => {
                               if ("Url" in not) {
                                   return not.ID === notification.ID
                               }
                           })

                           let updatedNotifications = [...newNotifications]
                           updatedNotifications[indexNotification] = response.data

                           setNewNotifications(updatedNotifications)
                       } else if (typeNotification === "old") {
                           let indexNotification = oldNotifications.findIndex(not => {
                               if ("ID" in not) {
                                   return not.ID === notification.ID
                               }
                           })

                           let updatedNotifications = [...oldNotifications]
                           updatedNotifications[indexNotification] = response.data

                           setOldNotifications(updatedNotifications)
                       }
                   }
               })
       }
    }

    const handlerDeleteNotification = (notification) => {
        FetchRequest("POST", "/delete_notification", notification)
            .then(response => {
                if (response.success) {
                    setOldNotifications(oldNotifications.filter(oldNotification => {
                        if ("Url" in oldNotification) {
                            return oldNotification.ID !== notification.ID
                        }

                        return true
                    }))
                    setNewNotifications(newNotifications.filter(newNotification => {
                        if ("Url" in newNotification) {
                            return newNotification.ID !== notification.ID
                        }

                        return true
                    }))
                }
            })
    }

    const handlerClickCustomNotification = (id, typeNotification) => {
        handlerSwitchIsShow()
        FetchRequest("GET", `/get_connected_tasks_projects/${id}`, null)
            .then(response => {
                if (response.success) {
                    if (typeNotification === "new") {
                        let indexNotification = newNotifications.findIndex(not => {
                            if ("Status" in not) {
                                return not.ID === id
                            }
                        })

                        let updatedNotifications = [...newNotifications]
                        updatedNotifications[indexNotification].ConnectedUser[0].IsChecked = true

                        setNewNotifications(updatedNotifications)
                    } else if (typeNotification === "old") {
                        let indexNotification = oldNotifications.findIndex(not => {
                            if ("CustomNotification" in not) {
                                return not.CustomNotification.ID === id
                            }
                        })

                        let updatedNotifications = [...oldNotifications]
                        updatedNotifications[indexNotification].IsChecked = true

                        setOldNotifications(updatedNotifications)
                    }

                    if (response.data != null) {
                        let updatedRecords = {
                            Tasks: [],
                            Projects: [],
                        }

                        if (response.data.Tasks != null) {
                            updatedRecords.Tasks = response.data.Tasks
                        }

                        if (response.data.Projects != null) {
                            updatedRecords.Projects = response.data.Projects
                        }

                        if (updatedRecords.Tasks.length === 1 && updatedRecords.Projects.length === 0) {
                            window.location.href = `/tasks/view/${updatedRecords.Tasks[0].ID}`
                        } else if (updatedRecords.Tasks.length === 0 && updatedRecords.Projects.length === 1) {
                            window.location.href = `/projects/view/${updatedRecords.Projects[0].ID}`
                        } else if (updatedRecords.Tasks.length > 0 || updatedRecords.Projects.length > 0) {
                            setCustomNotificationTasksProjects(updatedRecords)
                            setIsOpenConnectedTasksProjects(true)
                        }
                    } else {
                        setCustomNotificationTasksProjects({
                            Tasks: [],
                            Projects: [],
                        })
                    }
                }
            })
    }

    const handlerHideCustomNotification = (id) => {
        FetchRequest("GET", `/hide_custom_notification/${id}`, null)
            .then(response => {
                if (response.success) {
                    setOldNotifications(oldNotifications.filter(oldNotification => {
                        if ("CustomNotification" in oldNotification) {
                            return oldNotification.CustomNotification.ID !== id
                        }

                        return true
                    }))
                    setNewNotifications(newNotifications.filter(newNotification => {
                        if ("Status" in newNotification) {
                            return newNotification.ID !== id
                        }

                        return true
                    }))
                }
            })
    }

    return (
        <div className="notification-block">
            <div className="main-icon" onClick={handlerSwitchIsShow}>
                {isShowNotificationList ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBell} />}
                {countUnchecked > 0 && <div className="counter">{countUnchecked}</div>}
            </div>
            {isOpenNotificationModal && <NotificationModalCreate action={"create"} setModalCreate={setIsOpenNotificationModal} />}
            {isOpenNotificationModalControl && <NotificationControl setModalOpen={setIsOpenNotificationModalControl} />}
            {isOpenConnectedTasksProjects && <NotificationTasksProjects records={customNotificationTasksProjects} setModalOpen={setIsOpenConnectedTasksProjects}/>}
            <div className={isShowNotificationList ? "notification-list show" : "notification-list"}>
                {newNotifications.map(notification => (
                    "Status" in notification ?
                        <div className="notification" key={"custom-notification-"+notification.ID}>
                            <div className="custom-notification" onClick={() => handlerClickCustomNotification(notification.ID, "new")}>
                                <div className="text">{notification.Text}</div>
                                <span>{new Date(notification.LastSend.Int64).toLocaleString().slice(12,17) + " | " + new Date(notification.LastSend.Int64).toLocaleString().slice(0,10)}</span>
                            </div>
                            <div className="status" onClick={() => handlerHideCustomNotification(notification.ID)}>
                                <div className="delete"><FontAwesomeIcon icon={faTrash} /></div>
                                {!notification.ConnectedUser[0].IsChecked && <div className="uncheck"></div>}
                            </div>
                        </div>
                    :
                        <div className="notification" key={"notification-"+notification.ID}>
                            <Link to={notification.Url} onClick={() => handlerCheckNotification(notification, "new")}>
                                <div className="text">{notification.Text}</div>
                                <span>{new Date(notification.Date).toLocaleString().slice(12,17) + " | " + new Date(notification.Date).toLocaleString().slice(0,10)}</span>
                            </Link>
                        <div className="status" onClick={() => handlerDeleteNotification(notification)}>
                            <div className="delete"><FontAwesomeIcon icon={faTrash} /></div>
                                {!notification.IsChecked && <div className="uncheck"></div>}
                            </div>
                        </div>
                ))}
                {oldNotifications.map(notification => (
                    "CustomNotification" in notification ?
                        <div className="notification" key={"custom-notification-"+notification.CustomNotification.ID}>
                            <div className="custom-notification" onClick={() => handlerClickCustomNotification(notification.CustomNotification.ID, "old")}>
                                <div className="text">{notification.CustomNotification.Text}</div>
                                <span>{new Date(notification.CustomNotification.LastSend.Int64).toLocaleString().slice(12,17) + " | " + new Date(notification.CustomNotification.LastSend.Int64).toLocaleString().slice(0,10)}</span>
                            </div>
                            <div className="status" onClick={() => handlerHideCustomNotification(notification.CustomNotification.ID)}>
                                <div className="delete"><FontAwesomeIcon icon={faTrash} /></div>
                                {!notification.IsChecked && <div className="uncheck"></div>}
                            </div>
                        </div>
                    :
                        <div className="notification" key={"notification-"+notification.ID}>
                            <Link to={notification.Url} onClick={() => handlerCheckNotification(notification, "old")}>
                                <div className="text">{notification.Text}</div>
                                <span>{new Date(notification.Date).toLocaleString().slice(12,17) + " | " + new Date(notification.Date).toLocaleString().slice(0,10)}</span>
                            </Link>
                            <div className="status" onClick={() => handlerDeleteNotification(notification)}>
                                <div className="delete"><FontAwesomeIcon icon={faTrash} /></div>
                                {!notification.IsChecked && <div className="uncheck"></div>}
                            </div>
                        </div>
                ))}
                {(localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director" || canCreateNotification) &&
                    isShowNotificationList && <div className="btns-block">
                        <button className="create-notification" onClick={() => setIsOpenNotificationModal(true)}>Создать уведомление</button>
                        <button className="create-notification" onClick={() => setIsOpenNotificationModalControl(true)}>Управление уведомлениями</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Notification