import React, {useEffect, useState} from "react";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CustomSelectType1 = ({defaultValue, values, name, isOpen, setOpen, setSelectedValue, type}) => {
    const [selectValue, setSelectValue] = useState(defaultValue)

    useEffect(() => {
        setSelectValue(defaultValue)
    }, [defaultValue])

    const handlerChangeSelectValue = (value) => {
        setSelectedValue(value)
        setSelectValue(value.TranslateValue)
        if ("TranslateValue" in value) {
            setSelectValue(value.TranslateValue)
        } else {
            setSelectValue(value.Name)
        }
        setOpen(false)
    }

    const handlerSmartOpenSelect = () => {
        setOpen(!isOpen)
    }

    return(
        <div id={name} className={isOpen ? "custom-select-type1 active" : "custom-select-type1" } onClick={handlerSmartOpenSelect}>
            <div className="select-value">
                <span>{selectValue}</span>
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {type === "default" ?
                <div className={"values-list "+ name}>
                    {values.map(value => (
                        <span  key={"select-value-"+value.Value+"-"+value.ID} onClick={() => handlerChangeSelectValue(value)}>
                        {value.TranslateValue}
                    </span>
                    ))}
                </div>
            :
                <div className={"values-list "+ name}>
                    {values.map(value => (
                        <span key={"select-value-"+value.Login+"-"+value.ID} onClick={() => handlerChangeSelectValue(value)}>
                        {value.Name}
                    </span>
                    ))}
                </div>}
        </div>
    )
}

export default CustomSelectType1