import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import './App.css';
import PrivateRoute from "./components/PrivateRoute";
import Authorization from "./components/Authorization";
import HomePage from "./components/HomePage";
import TasksPage from "./components/TasksPage";
import TaskViewPage from "./components/TaskViewPage";
import UserViewPage from "./components/UserViewPage";
import UsersPage from "./components/UsersPage";
import EventsPage from "./components/EventsPage";
import ProfilePage from "./components/ProfilePage";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Authorization />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="" element={<HomePage />} />
                </Route>
                <Route path="/tasks" element={<PrivateRoute />}>
                    <Route path="" element={<TasksPage type={"task-all"} mainType={"task"}/>} />
                    <Route path="/tasks/my_tasks" element={<TasksPage type={"task-my"} mainType={"task"}/>} />
                    <Route path="/tasks/closed_tasks" element={<TasksPage type={"task-all_closed"} mainType={"task"}/>} />
                    <Route path="/tasks/my_closed_tasks" element={<TasksPage type={"task-my_closed"} mainType={"task"}/>} />
                    <Route path="/tasks/my_created_tasks" element={<TasksPage type={"task-my_created"} mainType={"task"}/>} />
                    <Route path="/tasks/deleted_tasks" element={<TasksPage type={"task-deleted"} mainType={"task"}/>} />
                    <Route path="/tasks/view/:taskID" element={<TaskViewPage type={"task"}/>} />
                </Route>
                <Route path="/projects" element={<PrivateRoute />}>
                    <Route path="" element={<TasksPage type={"project-all"} mainType={"project"}/>} />
                    <Route path="/projects/my_projects" element={<TasksPage type={"project-my"}/>} mainType={"project"}/>
                    <Route path="/projects/closed_projects" element={<TasksPage type={"project-all_closed"}/>} mainType={"project"}/>
                    <Route path="/projects/my_closed_projects" element={<TasksPage type={"project-my_closed"}/>} mainType={"project"}/>
                    <Route path="/projects/my_created_projects" element={<TasksPage type={"project-my_created"}/>} mainType={"project"}/>
                    <Route path="/projects/deleted_projects" element={<TasksPage type={"project-deleted"}/>} mainType={"project"}/>
                    <Route path="/projects/view/:projectID" element={<TaskViewPage type={"project"}/>} />
                </Route>
                <Route path="/events" element={<PrivateRoute />}>
                    <Route path="" element={<EventsPage type={"allEvents"} uri={"/get_events_all"} />} />
                    <Route path="/events/users" element={<EventsPage type={"userEvents"} uri={"/get_events_user"}/>} />
                    <Route path="/events/tasks" element={<EventsPage type={"taskEvents"} uri={"/get_events_task"}/>} />
                    <Route path="/events/projects" element={<EventsPage type={"projectEvents"} uri={"/get_events_project"}/>} />
                </Route>
                <Route path="/users" element={<PrivateRoute />}>
                    <Route path="" element={<UsersPage />} />
                    <Route path="/users/view/:userID" element={<UserViewPage />} />
                </Route>
                <Route path="/profile" element={<PrivateRoute />}>
                    <Route path="" element={<ProfilePage />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default App;
