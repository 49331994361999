import React from "react";

const Preload = () => {
    return (
        <div className="preload">
            <div className="preload-contain">
                <div className="preload-icon">
                    <img src="/rocket.svg" alt="" ></img>
                </div>
            </div>
        </div>
    )
}

export default Preload