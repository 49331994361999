import React, {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDiagramProject, faListCheck, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Preload from "./Preload";
import Table from "./Table";
import CustomSelectType2 from "./CustomSelectType2";
import TaskModalCreate from "./TaskModalCreate";
import Notification from "./Notification";
import FetchRequest from "../fetchRequest";
import ConfirmWindow from "./ConfirmWindow";

const TasksPage = ({type, mainType}) => {
    const [departments, setDepartments] = useState([])
    const [records, setRecords] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [url, setUrl] = useState("")
    const [isOpenModalTask, setIsOpenModalTask] = useState(false)
    const [isOpenModalProject, setIsOpenModalProject] = useState(false)
    const [isOpenDepartment, setIsOpenDepartment] = useState(false)
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)
    const [confirmWindowData, setConfirmWindowData] = useState({
        Record: null,
        Action: "",
    })
    const [confirmWindowText, setConfirmWindowText] = useState("")
    const [isOpenEditTaskWindow, setIsOpenEditTaskWindow] = useState(false)
    const [isOpenEditProjectWindow, setIsOpenEditProjectWindow] = useState(false)
    const [editRecord, setEditRecord] = useState({})

    useEffect(() => {
        FetchRequest("GET", "/get_departments", null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setDepartments(response.data)
                    } else {
                        setDepartments([])
                    }
                    setIsLoaded(true)
                }
            })
    }, [])

    useEffect(() => {
        setIsLoaded(false)
        switch (type) {
            case "task-all": setUrl("/get_tasks"); break;
            case "task-my": setUrl("/get_my_tasks"); break;
            case "task-all_closed": setUrl("/get_closed_tasks"); break;
            case "task-my_closed": setUrl("/get_my_closed_tasks"); break;
            case "task-my_created": setUrl("/get_my_created_tasks"); break;
            case "task-deleted": setUrl("/get_deleted_tasks"); break;
            case "project-all": setUrl("/get_projects"); break;
            case "project-my": setUrl("/get_my_projects"); break;
            case "project-all_closed": setUrl("/get_closed_projects"); break;
            case "project-my_closed": setUrl("/get_my_closed_projects"); break;
            case "project-my_created": setUrl("/get_my_created_projects"); break;
            case "project-deleted": setUrl("/get_deleted_projects"); break;
            default: setUrl("")
        }
    }, [type])

    const fetchRequest = useCallback((uri) => {
        FetchRequest("GET", uri, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {

                        let highestPriority = response.data.filter(record => record.Priority.Value === "highest")
                        let highPriority = response.data.filter(record => record.Priority.Value === "high")
                        let normalPriority = response.data.filter(record => record.Priority.Value === "normal")
                        let lowPriority = response.data.filter(record => record.Priority.Value === "low")
                        let lowestPriority = response.data.filter(record => record.Priority.Value === "lowest")
                        
                        setRecords([...highestPriority, ...highPriority, ...normalPriority, ...lowPriority, ...lowestPriority])
                    } else {
                        setRecords([])
                    }

                    setIsLoaded(true)
                }
            })
    }, [])

    useEffect(() => {
        if (url !== "") {
            fetchRequest(url)
        }
    }, [url, fetchRequest])

    const handlerApplyChanges = (task) => {
        let updatedRecords = [...records]
        let indexUpdatedRecords = updatedRecords.findIndex(record => {
            return record.ID === task.ID
        })

        updatedRecords[indexUpdatedRecords] = task

        let highestPriority = updatedRecords.filter(record => record.Priority.Value === "highest")
        let highPriority = updatedRecords.filter(record => record.Priority.Value === "high")
        let normalPriority = updatedRecords.filter(record => record.Priority.Value === "normal")
        let lowPriority = updatedRecords.filter(record => record.Priority.Value === "low")
        let lowestPriority = updatedRecords.filter(record => record.Priority.Value === "lowest")


        setRecords([...highestPriority, ...highPriority, ...normalPriority, ...lowPriority, ...lowestPriority])
    }

    const handlerAddTask = (task, action) => {
        let highestPriority = records.filter(record => record.Priority.Value === "highest")
        let highPriority = records.filter(record => record.Priority.Value === "high")
        let normalPriority = records.filter(record => record.Priority.Value === "normal")
        let lowPriority = records.filter(record => record.Priority.Value === "low")
        let lowestPriority = records.filter(record => record.Priority.Value === "lowest")

        switch (task.Priority.Value) {
            case "highest": highestPriority = [task, ...highestPriority]; break
            case "high": highPriority = [task, ...highPriority]; break
            case "low": lowPriority = [task, ...lowPriority]; break
            case "lowest": lowestPriority = [task, ...lowestPriority]; break
            default: normalPriority = [task, ...normalPriority]
        }


        setRecords([...highestPriority, ...highPriority, ...normalPriority, ...lowPriority, ...lowestPriority])
    };

    const taskAction = (task, typeAction) => {
        setIsOpenConfirmWindow(false)
        setConfirmWindowText("")
        setConfirmWindowData({
            Record: null,
            Action: "",
        })

        let url
        switch (typeAction) {
            case "task-accept": url = `/task_action/accept`; break;
            case "task-close": url = `/task_action/close`; break;
            case "task-reopen": url = `/task_action/reopen`; break;
            case "task-delete": url = `/task_action/delete`; break;
            case "project-accept": url = `/project_action/accept`; break;
            case "project-close": url = `/project_action/close`; break;
            case "project-reopen": url = `/project_action/reopen`; break;
            case "project-delete": url = `/project_action/delete`; break;
            default:
                url = ""
        }

        FetchRequest("POST", url, task)
            .then(response => {
                if (response.success) {
                    let updatedRecords = [...records]

                    switch (type) {
                        case "task-all":
                        case "project-all":
                        case "task-my":
                        case "project-my":
                            if (response.data.Status.Value === "new" || response.data.Status.Value === "processing" || response.data.Status.Value === "expire") {
                                let indexUpdatedRecord = updatedRecords.findIndex(record => {
                                    return record.ID === task.ID
                                })

                                updatedRecords[indexUpdatedRecord].Executor = response.data.Executor
                                updatedRecords[indexUpdatedRecord].IsClosed = response.data.IsClosed
                                updatedRecords[indexUpdatedRecord].Status = response.data.Status
                                updatedRecords[indexUpdatedRecord].CurrentUserIsOwner = response.data.CurrentUserIsOwner
                                updatedRecords[indexUpdatedRecord].AcceptedAt = response.data.AcceptedAt
                            } else {
                                updatedRecords = updatedRecords.filter(record => record.ID !== task.ID)
                            }
                            break
                        case "task-my_created":
                        case "project-my_created":
                            let indexUpdatedRecord = updatedRecords.findIndex(record => {
                                return record.ID === task.ID
                            })

                            updatedRecords[indexUpdatedRecord].Executor = response.data.Executor
                            updatedRecords[indexUpdatedRecord].IsClosed = response.data.IsClosed
                            updatedRecords[indexUpdatedRecord].Status = response.data.Status
                            updatedRecords[indexUpdatedRecord].CurrentUserIsOwner = response.data.CurrentUserIsOwner
                            break
                        default: updatedRecords = updatedRecords.filter(record => record.ID !== task.ID)
                    }

                    setRecords(updatedRecords)
                }
            })
    }

    const handlerSelectDepartment = (department) => {
        let url

        switch (type) {
            case "task-all": url = `/get_tasks_by_department/${department.ID}`; break;
            case "task-all_closed": url = `/get_closed_tasks_by_department/${department.ID}`; break;
            case "project-all": url = `/get_projects_by_department/${department.ID}`; break;
            case "project-all_closed": url = `/get_closed_projects_by_department/${department.ID}`; break;
            default: url = ""
        }

        FetchRequest("GET", url, null)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setRecords(response.data)
                    } else {
                        setRecords([])
                    }
                }
            })
    }

    const handlerConfirmAction = (task, action) => {
        let text
        switch (action) {
            case "task-accept": text = "Вы уверены, что хотите принять задачу в обработку?"; break
            case "task-close": text = "Вы уверены, что хотите закрыть задачу?"; break
            case "task-reopen": text = "Вы уверены, что хотите переоткрыть задачу?"; break
            case "task-delete": text = "Вы уверены, что хотите удалить задачу?"; break
            case "project-accept": text = "Вы уверены, что хотите принять проект в обработку?"; break;
            case "project-close": text = "Вы уверены, что хотите закрыть проект?"; break;
            case "project-reopen": text =  "Вы уверены, что хотите переоткрыть проект?"; break;
            case "project-delete": text =  "Вы уверены, что хотите удалить проект?"; break;
            default: text = ""
        }

        setConfirmWindowData({
            Record: task,
            Action: action
        })
        setIsOpenConfirmWindow(true)
        setConfirmWindowText(text)
    }

    const handlerEditRecord = (record, typeRecord) => {
        setEditRecord(record)
        if (typeRecord === "task") {
            setIsOpenEditTaskWindow(true)
        } else {
            setIsOpenEditProjectWindow(true)
        }
    }

    return (
        <section className={"tasks-page"}>
            <div className="head">
                {mainType === "task" ?
                    <div className="block">
                        <div className="icon"><FontAwesomeIcon icon={faListCheck} /></div>
                         <div className="text">
                            <div><Link to={"/tasks"}>Задачи</Link> / {type === "task-my" ? <Link to={"/tasks/my_tasks"}>Мои задачи</Link>
                                : type === "task-all_closed" ? <Link to={"/tasks/closed_tasks"}>Закрытые задачи</Link>
                                    : type === "task-my_closed" ? <Link to={"/tasks/my_closed_tasks"}>Мои закрытые задачи</Link>
                                        : type === "task-my_created" ? <Link to={"/tasks/my_created_tasks"}>Созданные мною задачи</Link> : ""}</div>
                            <h1>Задачи</h1>
                        </div>
                    </div>
                    : <div className="block">
                        <div className="icon"><FontAwesomeIcon icon={faDiagramProject} /></div>
                        <div className="text">
                            <div><Link to={"/projects"}>Проекты</Link> / {type === "project-my" ? <Link to={"/projects/my_projects"}>Мои проекты</Link>
                                : type === "project-all_closed" ? <Link to={"/projects/closed_projects"}>Закрытые проекты</Link>
                                    : type === "project-my_closed" ? <Link to={"/projects/my_closed_projects"}>Мои закрытые проекты</Link>
                                        : type === "project-my_created" ? <Link to={"/projects/my_created_projects"}>Созданные мною проекты</Link>  : ""}</div>
                            <h1>Проекты</h1>
                        </div>
                    </div>
                }
                <Notification />
            </div>
            {isOpenModalTask && <TaskModalCreate setModalCreate={setIsOpenModalTask} addTask={handlerAddTask}  type={"task"} action={"create"}/>}
            {isOpenEditTaskWindow && <TaskModalCreate setModalCreate={setIsOpenEditTaskWindow} addTask={handlerApplyChanges}  type={"task"} action={"edit"} editRecord={editRecord}/>}
            {isOpenEditProjectWindow && <TaskModalCreate setModalCreate={setIsOpenEditProjectWindow} addTask={handlerApplyChanges}  type={"project"} action={"edit"} editRecord={editRecord}/>}
            {isOpenModalProject && <TaskModalCreate setModalCreate={setIsOpenModalProject} addTask={handlerAddTask} type={"project"} action={"create"}/>}
            {isOpenConfirmWindow && <ConfirmWindow text={confirmWindowText} confirmFunction={() => taskAction(confirmWindowData.Record, confirmWindowData.Action)} setWindowState={setIsOpenConfirmWindow} />}
            <div className="table-contain">
                {mainType === "task" ?
                    <div className="sub-nav">
                        <Link to={"/tasks"}>
                            <span className={type === "task-all" ? "active" : ""}>Все задачи</span>
                        </Link>
                        <Link to={"/tasks/my_tasks"}>
                            <span className={type === "task-my" ? "active" : ""}>Мои задачи</span>
                        </Link>
                        <Link to={"/tasks/my_created_tasks"}>
                            <span className={type === "task-my_created" ? "active" : ""}>Созданные мною задачи</span>
                        </Link>
                        <Link to={"/tasks/closed_tasks"}>
                            <span className={type === "task-all_closed" ? "active" : ""}>Закрытые задачи</span>
                        </Link>
                        <Link to={"/tasks/my_closed_tasks"}>
                            <span className={type === "task-my_closed" ? "active" : ""}>Мои закрытые задачи</span>
                        </Link>
                        {(localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") &&
                            <Link to={"/tasks/deleted_tasks"}>
                                <span className={type === "task-deleted" ? "active" : ""}>Удаленные задачи</span>
                            </Link>
                        }
                    </div>
                :
                    <div className="sub-nav">
                        <Link to={"/projects"}>
                            <span className={type === "project-all" ? "active" : ""}>Все проекты</span>
                        </Link>
                        <Link to={"/projects/my_projects"}>
                            <span className={type === "project-my" ? "active" : ""}>Мои проекты</span>
                        </Link>
                        <Link to={"/projects/my_created_projects"}>
                            <span className={type === "project-my_created" ? "active" : ""}>Созданные мною проекты</span>
                        </Link>
                        <Link to={"/projects/closed_projects"}>
                            <span className={type === "project-all_closed" ? "active" : ""}>Закрытые проекты</span>
                        </Link>
                        <Link to={"/projects/my_closed_projects"}>
                            <span className={type === "project-my_closed" ? "active" : ""}>Мои закрытые проекты</span>
                        </Link>
                        {(localStorage.getItem("user-role") === "SuperAdmin" || localStorage.getItem("user-role") === "Director") &&
                            <Link to={"/projects/deleted_projects"}>
                                <span className={type === "project-deleted" ? "active" : ""}>Удаленные проекты</span>
                            </Link>
                        }
                    </div>}
                {isLoaded ?
                    mainType === "task" ?
                        type === "task-all" || type === "task-all_closed" ?
                            <Table records={records}
                                   type={"tasks"}
                                   controlButton={
                                       <button className={"create"}  onClick={() => setIsOpenModalTask(true)}>
                                           <FontAwesomeIcon icon={faPlus} /> <span>Создать задачу</span>
                                       </button>}
                                   additionalClass={"departments"}
                                   onClick3thIcon={{Action: handlerConfirmAction, Edit: handlerEditRecord}}
                                   customSelect={<CustomSelectType2 name="departmentSelect"
                                                                    defaultValue="Выбрать отдел"
                                                                    values={departments.map((department,index) => (
                                                                        <span key={"department-"+index} onClick={() => handlerSelectDepartment(department)}>{department.TranslateValue}</span>
                                                                    ))}
                                                                    isOpen={isOpenDepartment}
                                                                    setOpen={setIsOpenDepartment}/>}
                                   isOpenSelect={setIsOpenDepartment}
                                   reload={() => fetchRequest(url)}
                            /> : <Table records={records}
                                        type={"tasks"}
                                        controlButton={
                                            <button className={"create"} onClick={() => setIsOpenModalTask(true)}>
                                                <FontAwesomeIcon icon={faPlus} /> <span>Создать задачу</span>
                                            </button>}
                                        onClick3thIcon={{Action: handlerConfirmAction, Edit: handlerEditRecord}}
                                        isOpenSelect={setIsOpenDepartment}
                                        reload={() => fetchRequest(url)}
                            />
                        : type === "project-all" || type === "project-all_closed" ?
                            <Table records={records}
                                   type={"projects"}
                                   controlButton={
                                       <button className={"create"}  onClick={() => setIsOpenModalProject(true)}>
                                           <FontAwesomeIcon icon={faPlus} /> <span>Создать проект</span>
                                       </button>}
                                   additionalClass={"departments"}
                                   onClick3thIcon={{Action: handlerConfirmAction, Edit: handlerEditRecord}}
                                   customSelect={<CustomSelectType2 name="departmentSelect"
                                                                    defaultValue="Выбрать отдел"
                                                                    values={departments.map((department,index) => (
                                                                        <span key={"department-"+index} onClick={() => handlerSelectDepartment(department)}>{department.TranslateValue}</span>
                                                                    ))}
                                                                    isOpen={isOpenDepartment}
                                                                    setOpen={setIsOpenDepartment}/>}
                                   isOpenSelect={setIsOpenDepartment}
                                   reload={() => fetchRequest(url)}
                            /> : <Table records={records}
                                        type={"projects"}
                                        controlButton={
                                            <button className={"create"} onClick={() => setIsOpenModalProject(true)}>
                                                <FontAwesomeIcon icon={faPlus} /> <span>Создать проект</span>
                                            </button>}
                                        onClick3thIcon={{Action: handlerConfirmAction, Edit: handlerEditRecord}}
                                        isOpenSelect={setIsOpenDepartment}
                                        reload={() => fetchRequest(url)}
                            />
                : <Preload />}
            </div>
        </section>
    )
}

export default TasksPage