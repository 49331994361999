import {faUsers, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import UsersPageModalCreate from "./UsersPageModalCreate";
import Preload from "./Preload";
import Table from "./Table";
import ConfirmWindow from "./ConfirmWindow";
import Notification from "./Notification";
import FetchRequest from "../fetchRequest";

const UsersPage = () => {
    const isFirstRender = useRef(true)
    const [modalCreate, setModalCreate] = useState(false)
    const [users, setUsers] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [editUser, setEditUser] = useState({})
    const [confirmWindowBlock, setConfirmWindowBlock] = useState(false)
    const [targetUser, setTargetUser] = useState({})

    useEffect(() => {
        if (isFirstRender.current) {
            FetchRequest("GET", "/get_all_users", null)
                .then(response => {
                    if (response.success) {
                        if (response.data != null) {
                            setUsers(response.data)
                        } else {
                            setUsers([])
                        }
                        setIsLoaded(true)
                    }
                })

            isFirstRender.current = false
        }
    }, []);

    const handlerEditUser = (user) => {
        setEditUser(user)
        setModalEdit(true)
    }

    const handlerAddUser = (user) => {
        setUsers(prevUsers => [...prevUsers, user]);
    };

    const handlerSwitchStatusUser = (user) => {
        FetchRequest("POST", "/switch_status_user", user)
            .then(response => {
                if (response.success) {
                    if (response.data != null) {
                        setConfirmWindowBlock(false)
                        let updatedUsers = [...users]
                        let indexUpdatedUser = updatedUsers.findIndex(record => {
                            return record.ID === user.ID
                        })

                        updatedUsers[indexUpdatedUser].Baned = response.data.Baned

                        setUsers(updatedUsers)
                    }
                }
            })
    }

    let controlButton
    if (localStorage.getItem("user-role") !== "SuperAdmin" && localStorage.getItem("user-role") !== "Director") {
        controlButton = ""
    } else {
        controlButton = (
            <button className={"create"} onClick={() => setModalCreate(true)}><FontAwesomeIcon icon={faPlus} /> <span>Создать пользователя</span></button>
        )
    }

    const handlerApplyChangesUser = (user) => {
        let updatedUsers = [...users]
        let indexUpdatedUser = updatedUsers.findIndex(record => {
            return record.ID === user.ID
        })

        updatedUsers[indexUpdatedUser] = user

        setUsers(updatedUsers)
    }

    const handlerSetTargetUser = (user) => {
        setTargetUser(user)
        setConfirmWindowBlock(true)
    }

    return (
        <section className={"users-page"}>
            <div className="head">
                <div className="block">
                    <div className="icon"><FontAwesomeIcon icon={faUsers} /></div>
                    <div className="text">
                        <div><Link to={"/users"}>Пользователи</Link> /</div>
                        <h1>Пользователи</h1>
                    </div>
                </div>
                <Notification />
            </div>
            {confirmWindowBlock && <ConfirmWindow text={targetUser.Baned.Value ? "Вы уверены, что хотите разблокировать этого пользователя?" : "Вы уверены, что хотите заблокировать этого пользователя?"}
                                                  setWindowState={setConfirmWindowBlock}
                                                  confirmFunction={() => handlerSwitchStatusUser(targetUser)}  />}
            {modalCreate && <UsersPageModalCreate addUser={handlerAddUser} setModalCreate={setModalCreate} action={"create"}/>}
            {modalEdit && <UsersPageModalCreate addUser={handlerApplyChangesUser} setModalCreate={setModalEdit} action={"edit"} editUser={editUser}/>}
            {isLoaded ? <Table records={users} type={"users"} controlButton={controlButton} onClick3thIcon={{SwitchStatus: handlerSetTargetUser, EditUser: handlerEditUser}}/> : <Preload />}
        </section>
    )
}

export default UsersPage