import React from "react";

const ConfirmWindow = ({text, confirmFunction, setWindowState}) => {
    const handlerModalClose = (e) => {
        if (e.target.className === "confirm-window") {
            setWindowState(false)
        }
    }

    return (
        <div className="confirm-window" onMouseDown={handlerModalClose}>
            <div className="confirm-contain">
                <h3>{text}</h3>
                <div className="buttons">
                    <button className="create" onClick={confirmFunction}>Подтвердить</button>
                    <button className="cancel" onClick={() => {
                        setWindowState(false)
                    }}>Отмена</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmWindow