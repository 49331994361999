import React, {useEffect, useState} from "react";
import Preload from "./Preload"
import Table from "./Table"
import FetchRequest from "../fetchRequest";

const NotificationTasksProjects = ({records, setModalOpen}) => {
    // const [isLoaded, setIsLoaded] = useState(false)
    const [activeTable, setActiveTable] = useState("Tasks")
    // const [currentRecords, setCurrentRecords] = useState({
    //     Tasks: [],
    //     Projects: [],
    // })
    //
    // useEffect(() => {
    //     setCurrentRecords(records)
    // }, [records])

    const handlerModalCreateClose = (e) => {
        if (e.target.className === "modal-table") {
            setModalOpen(false)
        }
    }

    // useEffect(() => {
    //     console.log(notificationID)
    //     FetchRequest("GET", `/get_connected_tasks_projects/${notificationID}`, null)
    //         .then(response => {
    //             if (response.success) {
    //                 if (response.data != null) {
    //                     let updatedRecords = {...records}
    //
    //                     if (response.data.Tasks != null) {
    //                         updatedRecords.Tasks = response.data.Tasks
    //                     }
    //
    //                     if (response.data.Projects != null) {
    //                         updatedRecords.Projects = response.data.Projects
    //                     }
    //
    //                     setRecords(updatedRecords)
    //                 } else {
    //                     setRecords({
    //                         Tasks: [],
    //                         Projects: [],
    //                     })
    //                 }
    //                 setIsLoaded(true)
    //             }
    //         })
    // }, [notificationID])

    return (
        <div className="modal-table" onMouseDown={handlerModalCreateClose}>
            <div className="table-contain">
                <div className="sub-nav modal-sub-nav">
                    <span className={activeTable === "Tasks" ? "active" : ""} onClick={() => setActiveTable("Tasks")}>Задачи</span>
                    <span className={activeTable === "Projects" ? "active" : ""} onClick={() => setActiveTable("Projects")}>Проекты</span>
                </div>
                <Table records={records[activeTable]}
                       additionalClass={"modal"}
                       type={activeTable}
                       onClick3thIcon={null}
                />
            </div>
        </div>
    )
}

export default NotificationTasksProjects
