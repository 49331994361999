import {faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight, faMagnifyingGlass, faRotateRight, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState, useEffect, useCallback, useRef} from "react";
import TableSwitchHeaders from "./TableSwitchHeaders";
import TableSwitchRecords from "./TableSwitchRecords";
import switcher from "ai-switcher-translit"

const Table = ({records, controlButton, type, isOpenSelect, onClick3thIcon, additionalClass, customSelect, reload}) => {
    const [searchValue, setSearchValue] = useState("")
    const [countRecords, setCountRecords] = useState(15)
    const [currentPage, setCurrentPage] = useState(1)
    const [allPage, setAllPage] = useState([])
    const [showPages, setShowPages] = useState(null)
    const [showRecords, setShowRecords] = useState([])
    const [recordsType, setRecordsType] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const [allRecords, setAllRecords] = useState(records)
    const sortData = {
        LastTarget: "",
        Reverse: false,
    }
    const [isOpenSmallCustomSelect, setIsOpenSmallCustomSelect] = useState(false)
    const smallCustomSelect = useRef(null)

    useEffect(() => {
        setAllRecords(records)
    }, [records])

    const handlerSwitchSelectBlock = useCallback((e) => {
        // const customSmallSelect = e.target.closest(".custom-select");
        // console.log(customSmallSelect)
        // console.log(smallCustomSelect.current)
        // console.log(smallCustomSelect.current === customSmallSelect)
        if (smallCustomSelect.current === e.target.closest(".custom-select")) {
            setIsOpenSmallCustomSelect(prevState => !prevState)
        } else {
            setIsOpenSmallCustomSelect(false)
        }

        let select = document.querySelector(".custom-select-type2")
        if (select && customSelect) {
            if (e.target.closest(".custom-select-type2") !== select) {
                isOpenSelect(false)
            }
        }
    }, [isOpenSelect])

    useEffect(() => {
        document.addEventListener("click", handlerSwitchSelectBlock);

        return () => {
            document.removeEventListener("click", handlerSwitchSelectBlock);
        };
    }, [handlerSwitchSelectBlock]);

    useEffect(() => {
        if (showRecords.length > 0) {
            setIsLoaded(true)
        }
    }, [showRecords])


    useEffect(() => {
        setIsLoaded(false)
        if (searchValue !== "" && allRecords.length > 0) {
            let translitStr = switcher.getSwitch(searchValue)
            setShowRecords(allRecords.filter(record => {
                for (let key in record) {
                    switch (key) {
                        case "ID":
                            if (String(record[key]).includes(searchValue.trim())) return true;
                            break
                        case "Login":
                            if (record[key].toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "User":
                        case "EditUser":
                        case "Task":
                            if (record[key].Name.toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "Executor":
                        case "Creator":
                            if (record[key].Name.toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "Role":
                        case "ExecutiveDepartment":
                        case "Gender":
                        case "Baned":
                        case "Priority":
                        case "Status":
                            if (record[key].TranslateValue.toLowerCase().includes(translitStr.trim().toLowerCase())) return true;
                            break
                        case "Email":
                        case "Phone":
                            if (record[key].String.toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "PhoneNumber":
                            if (record[key].filter(phone => phone.includes(searchValue.trim())).length > 0) return true;
                            break
                        case "CreatedAt":
                        case "UpdatedAt":
                            if (new Date(record[key]).toLocaleString().slice(0,10).toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "Date":
                            if (new Date(record[key]).toLocaleString().toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        case "PeriodExecution":
                            if (new Date(record[key].Int64).toLocaleString().slice(0,10).toLowerCase().includes(searchValue.trim().toLowerCase())) return true;
                            break
                        default:
                            if (typeof record[key] === "string" && record[key].toLowerCase().includes(translitStr.toLowerCase())) return true
                    }
                }
                return false
            }).slice((currentPage - 1) * countRecords, currentPage * countRecords));
        } else {
            let updatedRecords = allRecords.slice((currentPage - 1) * countRecords, currentPage * countRecords)
            setShowRecords(updatedRecords);
        }
        // setCountPage(Math.ceil(records.length/countRecords))
        setAllPage(Array.from({ length: Math.ceil(allRecords.length/countRecords) }, (_, index) => (
            <span
                key={index}
                className={index + 1 === currentPage ? 'active' : ''}
                onClick={() => setCurrentPage(index + 1)}
            >
                {index + 1}
            </span>
        )))

        if (Math.ceil(allRecords.length/countRecords) !== 0 && Math.ceil(allRecords.length/countRecords) < currentPage) {
            setCurrentPage(Math.ceil(allRecords.length/countRecords))
        }
        setRecordsType(type)
    }, [allRecords, currentPage, countRecords, searchValue, type])

    useEffect(() => {
        if (allPage.length <= 7) {
            setShowPages(
                <div className="pages">
                    {allPage}
                </div>)
        } else {
            let pagesSlice = [allPage[0]]

            if (currentPage > 4) {
                pagesSlice.push(<span key={"space-1"}>...</span>)
            }

            let startPoint = currentPage - 3
            let endPoint = currentPage + 2

            if (startPoint <= 1) {
                endPoint += (startPoint-1) * -1
                startPoint = 1
            } else if (endPoint >= allPage.length) {
                startPoint -= (allPage.length-currentPage-3) * -1
                endPoint = allPage.length-1
            }

            for (let i = startPoint; i < endPoint; i++) {
                pagesSlice.push(allPage[i])
            }

            if (currentPage <= allPage.length-4) {
                pagesSlice.push(<span key={"space-2"}>...</span>)
            }

            pagesSlice.push(allPage[allPage.length-1])

            setShowPages(<div className="pages">
                {pagesSlice}
            </div>)
        }
    }, [allPage, currentPage])

    const handlerChangeSearchValue = (e) => {
        setSearchValue(e.target.value)
        if (e.target.value === "") {
            document.querySelector("#search").classList.remove("valid")
        } else {
            document.querySelector("#search").classList.add("valid")
        }
    }

    const handlerSort = useCallback((typeSort, target, contain) => {
        if (target === sortData.LastTarget) {
            sortData.Reverse = !sortData.Reverse
        } else {
            sortData.Reverse = false
            sortData.LastTarget = target
        }

        let updatedRecords = [...records]

        if (typeSort === "importance") {
            let highestPriority = updatedRecords.filter(record => record.Priority.Value === "highest")
            let highPriority = updatedRecords.filter(record => record.Priority.Value === "high")
            let normalPriority = updatedRecords.filter(record => record.Priority.Value === "normal")
            let lowPriority = updatedRecords.filter(record => record.Priority.Value === "low")
            let lowestPriority = updatedRecords.filter(record => record.Priority.Value === "lowest")

            if (sortData.Reverse) {
                setAllRecords([...highestPriority, ...highPriority, ...normalPriority, ...lowPriority, ...lowestPriority])
            } else {
                setAllRecords([...lowestPriority, ...lowPriority, ...normalPriority, ...highPriority, ...highestPriority])
            }
        } else {
            setAllRecords(updatedRecords.sort(function (record1, record2) {
                let value1, value2

                switch (contain) {
                    case null: value1 = record1[target]; value2 = record2[target]; break
                    case "Enum": value1 = record1[target].TranslateValue; value2 = record2[target].TranslateValue; break
                    case "String": value1 = record1[target].String; value2 = record2[target].String; break
                    case "Int64": value1 = record1[target].Int64; value2 = record2[target].Int64; break
                    case "User": value1 = record1[target].Name; value2 = record2[target].Name; break
                    default: value1 = record1[target]; value2 = record2[target]
                }

                if (!value1) {
                    return 1
                } else if (!value2) {
                    return -1
                } else {
                    if (sortData.Reverse) {
                        if (typeSort === "string") {
                            return value2.localeCompare(value1);
                        } else {
                            return value1 - value2;
                        }
                    } else {
                        if (typeSort === "string") {
                            return value1.localeCompare(value2);
                        } else {
                            return value2 - value1;
                        }
                    }
                }
            }))
        }
    },[records])

    return (
        <div className={"table "+recordsType+" "+additionalClass}>
            <div className="control">
                <div className="switch-count-records">
                    <span>Показывать по</span>
                    <div className={isOpenSmallCustomSelect ? "custom-select small active" : "custom-select small"} ref={smallCustomSelect}>
                        <div className="value-block">
                            <div className="value">{countRecords}</div>
                            <div className="icon"><FontAwesomeIcon icon={faCaretDown} /></div>
                        </div>
                        <div className="values-block">
                            <span onClick={() => setCountRecords(15)}>15</span>
                            <span onClick={() => setCountRecords(20)}>20</span>
                            <span onClick={() => setCountRecords(50)}>50</span>
                            <span onClick={() => setCountRecords(100)}>100</span>
                        </div>
                    </div>
                    <span>записей</span>
                </div>
                {additionalClass && customSelect}
                <div>
                    <label id={"search"}>
                        <input type="text" value={searchValue} placeholder={"Поиск..."} onChange={(e) => handlerChangeSearchValue(e)}/>
                        <span className={"icon"}><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    </label>
                    {controlButton}
                    <button className={"reload"} onClick={reload}><FontAwesomeIcon icon={faRotateRight} /></button>
                </div>
            </div>
            <div className="main-table-contain">
                <table>
                    <thead>
                    <TableSwitchHeaders type={type} handlerSort={handlerSort}/>
                    </thead>
                    <tbody>
                    {isLoaded && showRecords.map((record, index) => (
                        <TableSwitchRecords key={'record-'+type+"-"+record.ID+"-"+index} type={recordsType} record={record} index={index} onClick3thIcon={onClick3thIcon} />
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <div className="count-records">Показано {showRecords.length === 0 ? (currentPage-1)*countRecords : (currentPage-1)*countRecords+1}-{showRecords.length+(countRecords*(currentPage-1))} из {allRecords.length} записей</div>
                <div className="start" onClick={() => setCurrentPage(1)}><FontAwesomeIcon icon={faAnglesLeft} /></div>
                <div className="back" onClick={() => {
                    if (currentPage-1 > 0) {
                        setCurrentPage(currentPage - 1)
                    }
                }}><FontAwesomeIcon icon={faAngleLeft} /></div>

                {showPages}
                <div className="next" onClick={() => {
                    if (currentPage+1 <= allPage.length) {
                        setCurrentPage(currentPage+1)
                    }
                }}><FontAwesomeIcon icon={faAngleRight} /></div>
                <div className="end" onClick={() => setCurrentPage(allPage.length)}><FontAwesomeIcon icon={faAnglesRight} /></div>
            </div>
        </div>
    )
}

export default Table