import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Table from "./Table";
import Preload from "./Preload";
import FetchRequest from "../fetchRequest";
import ConfirmWindow from "./ConfirmWindow";
import NotificationModalCreate from "./NotificationModalCreate";

const NotificationControl = ({setModalOpen}) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [isOpenConfirmWindow, setIsOpenConfirmWindow] = useState(false)
    const [confirmWindowData, setConfirmWindowData] = useState({
        Notification: "",
        Action: "",
        Text: "",
    })
    const [editNotification, setEditNotification] = useState(null)
    const [isOpenEditNotificationWindow, setIsOpenEditNotificationWindow] = useState(false)

    const handlerModalTableClose = (e) => {
        if (e.target.className === "modal-table") {
            setModalOpen(false)
        }
    }

    useEffect(() => {
        FetchRequest("GET", "/get_all_custom_notifications", null)
            .then(response => {
                if (response.success) {
                    setIsLoaded(true)
                    if (response.data != null) {
                        setNotifications(response.data)
                    } else {
                        setNotifications([])
                    }
                }
            })
    }, [])

    const handlerConfirmAction = (notification, action) => {
        let text
        switch (action) {
            case "pause":text = "Вы уверены, что хотите приостановить отправку этого уведомления?"; break
            case "unpause": text = "Вы уверены, что хотите возобновить отправку этого уведомления?"; break
            case "delete": text = "Вы уверены, что хотите безвозвратно удалить это уведомление?"; break
            default:
                text = ""
                return
        }

        setConfirmWindowData({
            Notification: notification,
            Action: action,
            Text: text,
        })
        setIsOpenConfirmWindow(true)
    }

    const handlerNotificationAction = (notification, action) => {
        setIsOpenConfirmWindow(false)
        setConfirmWindowData({
            Notification: null,
            Action: "",
            Text: "",
        })

        FetchRequest("POST", `/notification_action/${action}`, notification)
            .then(response => {
                if (response.success) {
                    if (action === "delete") {
                       setNotifications(prevNotifications => prevNotifications.filter(not => not.ID !== notification.ID))
                    } else {
                        let updatedNotifications = [...notifications]
                        let indexUpdatedNotification = updatedNotifications.findIndex(not => not.ID === notification.ID)
                        if (indexUpdatedNotification > -1) {
                            updatedNotifications[indexUpdatedNotification] = response.data
                        }

                        setNotifications(updatedNotifications)
                    }
                }
            })
    }

    const handlerEditNotification = (notification) => {
        setEditNotification(notification)
        setIsOpenEditNotificationWindow(true)
    }

    const handlerApplyChange = (notification) => {
        setNotifications(prevState => {
            let updatedNotifications = [...prevState]
            let indexNotification = updatedNotifications.findIndex(not => not.ID === notification.ID)

            updatedNotifications[indexNotification] = notification

            return updatedNotifications
        })
    }

    return (
        <div className="modal-table" onMouseDown={handlerModalTableClose}>
            {isOpenConfirmWindow && <ConfirmWindow text={confirmWindowData.Text} setWindowState={setIsOpenConfirmWindow}
                                                   confirmFunction={() => handlerNotificationAction(confirmWindowData.Notification, confirmWindowData.Action)}/>}
            {isOpenEditNotificationWindow && <NotificationModalCreate action={"edit"} editNotification={editNotification} setModalCreate={setIsOpenEditNotificationWindow} applyChange={handlerApplyChange}/>}
            <div className="contain">
                <div className="table-contain" style={{height: "100%"}}>
                    {isLoaded ?
                        <Table records={notifications}
                               type={"notifications"}
                               additionalClass={"modal"}
                               onClick3thIcon={{Action: handlerConfirmAction, Edit: handlerEditNotification}}
                        />
                        :
                        <Preload />
                    }
                </div>
            </div>
        </div>
    )
}

export default NotificationControl